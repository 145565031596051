import format from 'date-fns/format';
import { useMemo, useState } from 'react';
import { queryCache, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Card } from 'refreshed-component/atoms/Card';
import { Dropdown, type DropdownItem } from 'refreshed-component/atoms/Dropdown';
import { Empty } from 'refreshed-component/atoms/Empty';
import FiatDepositForm from 'refreshed-component/forms/FiatDepositForm';
import FiatWithdrawForm from 'refreshed-component/forms/FiatWithdrawForm';
import { ConfirmModal } from 'refreshed-component/molecules/ConfirmModal';
import Loading from 'refreshed-component/molecules/Loading';
import Modal from 'refreshed-component/molecules/Modal';
import { Pagination } from 'refreshed-component/molecules/Pagination';
import { SummaryCard } from 'refreshed-component/molecules/SummaryCard';
import { SummaryHolder } from 'refreshed-component/molecules/SummaryHolder';
import { toast } from 'refreshed-component/molecules/toast';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { Table } from 'refreshed-component/templates/Table';

import { Button, ButtonVariant, Icon, IconName, Text, TextAlign, TextColor, TypographyVariant } from '@aircarbon/ui';
import { AssetCategory, type AssetCategoryCode, logger } from '@aircarbon/utils-common';
import { formatter, generateDepositReference } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';
import { usePairs } from 'pages/account/trading/hooks/usePairs';

import { Account } from 'state/account';
import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useAccountBalances from 'hooks/useAccountBalances';
import { FiatHistory, type Status, StatusLabel, useFiatHistory } from 'hooks/useFiatHistory';

const { formatNumber } = formatter;

export const Cash = () => {
  const history = useHistory();
  const { consolidatedTokens, refetchAccountData, accountUsers } = Account.useContainer();
  const {
    rootUser,
    selector: { getAuthToken, getUserRootId, getUserId, getAccountAddress, isBankAccountApproved },
    status: { canDeposit, canWithdraw, hasBankAccount },
  } = User.useContainer();
  const {
    selector: { mainCcyCode, mainCcyNumDecimals, mainCcySymbol, availableCurrencies },
  } = Entity.useContainer();
  const { getSetting } = UI.useContainer();

  const depositAccountReferencePrefix = getSetting('web_settings_depositAccountReferencePrefix');
  const accountReference = generateDepositReference({
    rootUserId: getUserRootId(),
    tradingName: rootUser?.tradingName,
    accountReferencePrefix: depositAccountReferencePrefix,
  });
  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});

  const {
    balances,
    selector: {
      totalOpenQuantities,
      getTotalAvailableAmount,
      getAvailableAmountByAddress,
      getTotalBalance,
      getTotalCurrencyBalanceByAddress,
      totalTokenQuantity,
    },
  } = useAccountBalances(accountUsers.filter((user) => !!user.account).map((user) => user.user_id));

  const [depositStatusDropdownItem, setStatusDropdownItemForDeposit] = useState<DropdownItem | null>(null);
  const [withdrawStatusDropdownItem, serStatusDropdownItemForWithdraw] = useState<DropdownItem | null>(null);

  const { data: fiatDepositHistory, pagination: fiatDepositPagination } = useFiatHistory(
    FiatHistory.Deposit,
    depositStatusDropdownItem?.id ? [depositStatusDropdownItem.id as Status] : [],
  );
  const { data: fiatWithdrawHistory, pagination: fiatWithdrawPagination } = useFiatHistory(
    FiatHistory.Withdraw,
    withdrawStatusDropdownItem?.id ? [withdrawStatusDropdownItem.id as Status] : [],
  );

  const { getPairByAssetId } = usePairs({
    assetCategories: [AssetCategory[AssetCategory.token] as AssetCategoryCode],
    includeMarketData: true,
  });

  const filteredBalances = useMemo(() => balances?.filter((item) => !!item.accountAddress), [balances]);
  const finalAssets = useMemo(() => Object.values(consolidatedTokens).filter((item) => item.qty), [consolidatedTokens]);

  const totalCash =
    useMemo(
      () =>
        filteredBalances?.reduce((previousValue: number, currentValue: { balance: number }) => {
          return previousValue + currentValue.balance;
        }, 0),
      [filteredBalances],
    ) ?? 0;

  const totalOpenCash = useMemo(
    () =>
      filteredBalances?.reduce(
        (previousValue: number, currentValue: { balance: number; availableAmount: number }): number => {
          const openAmount = currentValue?.balance - (currentValue?.availableAmount ?? 0);
          return previousValue + openAmount;
        },
        0,
      ),
    [filteredBalances],
  );

  const assetValue = useMemo(
    () =>
      finalAssets.reduce((previousValue, currentValue) => {
        const pair = getPairByAssetId(currentValue.asset.id);
        const assetQuantity = currentValue.isCurrency
          ? getTotalBalance() || 0
          : totalTokenQuantity(undefined, currentValue.asset.scId);

        return previousValue + (pair?.marketData?.referencePrice || 0) * assetQuantity;
      }, 0),
    [finalAssets, getPairByAssetId, getTotalBalance, totalTokenQuantity],
  );

  const openAssetValue = useMemo(
    () =>
      finalAssets.reduce((previousValue, currentValue) => {
        const pair = getPairByAssetId(currentValue.asset.id);
        const baseTokenTypeId = currentValue.asset.scId;

        const qty =
          totalOpenQuantities({
            ...(baseTokenTypeId !== undefined && pair?.symbol
              ? { pair: { symbol: pair.symbol, baseTokenTypeId } }
              : undefined),
          }) || 0;

        return previousValue + (pair?.marketData?.referencePrice || 0) * qty;
      }, 0),
    [finalAssets, getPairByAssetId, totalOpenQuantities],
  );

  const totalAssetQty = useMemo(
    () =>
      finalAssets.reduce((previousValue, currentValue) => {
        const totalTokenQty = totalTokenQuantity(undefined, currentValue.asset.scId);
        return previousValue + (currentValue.isCurrency ? 0 : totalTokenQty);
      }, 0),
    [finalAssets],
  );

  const totalOpenAsset = useMemo(() => totalOpenQuantities({}), [totalOpenQuantities]);

  const [addDeposit] = useMutation(
    async (formData: Record<string, any>) => {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      return fetch(`/api/user/fiat/deposit-user-notify`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          amount: Number(formData.amount),
          ccyTypeId: Number(formData.ccyTypeId),
          userId: getUserId(),
          account: getAccountAddress(),
          depositRef: accountReference,
        }),
      });
    },
    {
      onSuccess: async (data) => {
        if (data.ok) {
          queryCache.invalidateQueries('deposit');
          await refetchAccountData();
          toast.success('Thank you. We will be expecting your deposit.');
        } else {
          logger.warn(data);
          const error = await data.json();
          toast.error(error?.message ?? 'Something went wrong!');
          logger.warn({ error });
        }
      },
      onError: (error: Error) => {
        toast.error(error?.message ?? 'Something went wrong!');
        logger.error(error);
      },
    },
  );

  const [addWithdraw] = useMutation(
    async (formData: Record<string, any>) => {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      return fetch(`/api/user/fiat/withdraw-user-notify`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          ...formData,
          amount: Number(formData.amount),
          ccyTypeId: Number(formData.ccyTypeId),
          userId: getUserId(),
          account: getAccountAddress(),
        }),
      });
    },
    {
      onSuccess: async (data) => {
        if (data.ok) {
          queryCache.invalidateQueries('withdraw');
          await refetchAccountData();
          toast.default('Submitting withdrawal request. It may take several seconds.');
          return;
        }
        const error = await data.json();
        logger.error(error);
        toast.error(error?.message ?? 'Something went wrong!');
      },
      onError: (error: Error) => {
        toast.error(error?.message ?? 'Something went wrong!');
        logger.error(error);
      },
    },
  );

  if (isLoadingMarketSettings) return <Loading />;

  return (
    <PageHolder>
      <PageSections>
        <PageControls
          title="Overview"
          controls={{
            secondary: (
              <>
                {canDeposit() &&
                  (isBankAccountApproved() ? (
                    <Modal
                      title={'Deposit'}
                      action={
                        <Button
                          variant={ButtonVariant.outlined}
                          isDisabled={marketSettings?.fiatEntryEnabled === 0}
                          endIcon={IconName.Deposit}
                        >
                          Deposit
                        </Button>
                      }
                    >
                      {({ onClose }) => (
                        <FiatDepositForm
                          onSubmit={(
                            formData: Record<string, any>,
                            handlers: { onSuccess?: (() => void) | undefined } | null | undefined,
                          ) => {
                            addDeposit(formData, {
                              onSuccess: (data) => {
                                onClose();
                                if (data.ok && handlers?.onSuccess) {
                                  handlers?.onSuccess();
                                }
                              },
                            });
                          }}
                          accountReference={accountReference}
                        />
                      )}
                    </Modal>
                  ) : (
                    <ConfirmModal
                      title="Bank Account"
                      action={
                        <Button
                          variant={ButtonVariant.outlined}
                          isDisabled={marketSettings?.fiatEntryEnabled === 0}
                          endIcon={IconName.Deposit}
                        >
                          Deposit
                        </Button>
                      }
                      accept={{
                        label: 'Go to Bank Details',
                        icon: 'no-icon',
                        callback() {
                          history.push('/account/company-settings/bank-details');
                          return false;
                        },
                      }}
                      cancel={{
                        label: 'Cancel',
                        icon: 'no-icon',
                      }}
                    >
                      <div className="flex flex-col items-center gap-base">
                        <Text
                          align={TextAlign.center}
                          color={TextColor.secondary}
                          variant={TypographyVariant.subtitle1}
                        >
                          {!hasBankAccount() && 'Please fill your bank details first.'}
                          {hasBankAccount() &&
                            !isBankAccountApproved() &&
                            'Please wait for your bank account to be approved.'}
                        </Text>
                      </div>
                    </ConfirmModal>
                  ))}
                {canWithdraw() &&
                  (isBankAccountApproved() ? (
                    <Modal
                      title={'Withdraw'}
                      action={
                        <Button isDisabled={marketSettings?.fiatEntryEnabled === 0} endIcon={IconName.Withdraw}>
                          Withdraw
                        </Button>
                      }
                    >
                      {({ onClose }) => (
                        <FiatWithdrawForm
                          onSubmit={(formData: Record<string, any>, resetForm: () => void) => {
                            addWithdraw(formData, {
                              onSuccess: (data) => {
                                onClose();
                                if (data.ok && resetForm) {
                                  resetForm();
                                }
                              },
                            });
                          }}
                        />
                      )}
                    </Modal>
                  ) : (
                    <ConfirmModal
                      title="Bank Account"
                      action={
                        <Button isDisabled={marketSettings?.fiatEntryEnabled === 0} endIcon={IconName.Withdraw}>
                          Withdraw
                        </Button>
                      }
                      accept={{
                        label: 'Go to Bank Details',
                        icon: 'no-icon',
                        callback() {
                          history.push('/account/company-settings/bank-details');
                          return false;
                        },
                      }}
                      cancel={{
                        label: 'Cancel',
                        icon: 'no-icon',
                      }}
                    >
                      <div className="flex flex-col items-center gap-base">
                        <Text
                          align={TextAlign.center}
                          color={TextColor.secondary}
                          variant={TypographyVariant.subtitle1}
                        >
                          {!hasBankAccount() && 'Please fill your bank details first.'}
                          {hasBankAccount() &&
                            !isBankAccountApproved() &&
                            'Please wait for your bank account to be approved.'}
                        </Text>
                      </div>
                    </ConfirmModal>
                  ))}
              </>
            ),
          }}
        />
      </PageSections>
      <PageSections>
        <SummaryHolder>
          <SummaryCard
            title="Available Balance"
            value={`${mainCcyCode}${formatNumber(totalCash, mainCcyNumDecimals)}`}
            icon={<Icon name={IconName.Wallet} />}
            secondaryValue={
              totalOpenCash > 0 ? `-${mainCcyCode}${formatNumber(totalOpenCash, mainCcyNumDecimals)}` : undefined
            }
            secondaryValueColor={TextColor.error}
            secondaryTooltip={`Reserved Cash Amount`}
          />
          <SummaryCard
            title="Assets Value"
            tooltip={`Total assets value in ${mainCcySymbol}`}
            value={`${mainCcyCode}${formatNumber(assetValue, mainCcyNumDecimals)}`}
            icon={<Icon name={IconName.Bids} />}
            secondaryValue={
              openAssetValue ? `-${mainCcyCode}${formatNumber(openAssetValue, mainCcyNumDecimals)}` : undefined
            }
            secondaryValueColor={TextColor.error}
            secondaryTooltip={`Reserved Asset Value`}
          />
          <SummaryCard
            title="Total Qty (tCO2)"
            tooltip={'Total quantity for all contracts in tCO2'}
            value={formatNumber(totalAssetQty, 0) ?? ''}
            icon={<Icon name={IconName.Database} />}
            secondaryValue={totalOpenAsset > 0 ? (`-${formatNumber(totalOpenAsset, 0)}` ?? '') : undefined}
            secondaryValueColor={TextColor.error}
            secondaryTooltip={`Reserved Asset Quantity`}
          />
        </SummaryHolder>
      </PageSections>
      {canDeposit() ? (
        <PageSections>
          <div className="flex flex-row gap-4 w-full h-full">
            <Card className={'flex flex-col'}>
              <Text variant={TypographyVariant.h6Title}>Deposit History</Text>
              <div className="flex flex-row gap-4 justify-start w-full">
                <Dropdown
                  config={{
                    color: 'gray',
                  }}
                  list={Object.entries(StatusLabel).map(([key, label]) => {
                    return {
                      id: key,
                      label,
                    };
                  })}
                  onSelectItem={(item) => {
                    setStatusDropdownItemForDeposit(item);
                  }}
                >
                  <div className="flex flex-row gap-2">
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                      Status:
                    </Text>
                    <Text variant={TypographyVariant.body2}>{depositStatusDropdownItem?.label || 'All'}</Text>
                  </div>
                </Dropdown>
              </div>
              {fiatDepositHistory.resolvedData?.items && (
                <Table
                  config={{
                    sticky: {
                      left: ['id'],
                      right: [],
                    },
                    columns: {
                      id: {
                        label: '#',
                        minWidth: 50,
                      },
                      date: {
                        label: 'DATE',
                        minWidth: 300,
                      },
                      value: {
                        label: `VALUE (${mainCcySymbol})`,
                        minWidth: 150,
                      },
                      valueReceived: {
                        label: `VALUE RECEIVED (${mainCcySymbol})`,
                        minWidth: 150,
                      },
                      status: {
                        label: 'STATUS',
                      },
                    },
                    rows: fiatDepositHistory.resolvedData?.items.map((item, index) => {
                      const date = format(new Date(item.created_utc), 'MMM d yyyy h:mm:ss a');
                      const value = `${availableCurrencies[item.ccy_type_id]} ${formatter.formatCurrency(item.amount, 2)}`;
                      const valueReceived = `${availableCurrencies[item.ccy_type_id]} ${formatter.formatCurrency(item.amount_received, 2)}`;
                      return {
                        id: item.id.toString(),
                        date,
                        value,
                        valueReceived,
                        status: item.status_code,
                        _key: item.id.toString(),
                      };
                    }),
                    loading: fiatDepositHistory.isLoading,
                    whenNoData: (
                      <Empty title="No cash deposits" description="You haven't made any cash deposits yet." />
                    ),
                  }}
                />
              )}
              <Pagination total={fiatDepositHistory.resolvedData?.total || 0} actions={fiatDepositPagination} />
            </Card>
          </div>
        </PageSections>
      ) : undefined}
      {canWithdraw() ? (
        <PageSections>
          <div className="flex flex-row gap-4 w-full h-full">
            <Card className={'flex flex-col'}>
              <Text variant={TypographyVariant.h6Title}>Withdrawal History</Text>
              <div className="flex flex-row gap-4 justify-start w-full">
                <Dropdown
                  config={{
                    color: 'gray',
                  }}
                  list={Object.entries(StatusLabel).map(([key, label]) => {
                    return {
                      id: key,
                      label,
                    };
                  })}
                  onSelectItem={(item) => {
                    serStatusDropdownItemForWithdraw(item);
                  }}
                >
                  <div className="flex flex-row gap-2">
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                      Status:
                    </Text>
                    <Text variant={TypographyVariant.body2}> {withdrawStatusDropdownItem?.label || 'All'}</Text>
                  </div>
                </Dropdown>
              </div>
              {fiatWithdrawHistory.resolvedData?.items && (
                <Table
                  config={{
                    sticky: {
                      left: ['id'],
                      right: [],
                    },
                    columns: {
                      id: {
                        label: '#',
                        minWidth: 50,
                      },
                      date: {
                        label: 'DATE',
                        minWidth: 300,
                      },
                      value: {
                        label: `VALUE (${mainCcySymbol})`,
                      },
                      valueSent: {
                        label: `VALUE SENT (${mainCcySymbol})`,
                      },
                      status: {
                        label: 'STATUS',
                      },
                    },
                    rows: fiatWithdrawHistory.resolvedData?.items.map((item, index) => {
                      const date = format(new Date(item.created_utc), 'MMM d yyyy h:mm:ss a');
                      const value = `${availableCurrencies[item.ccy_type_id]} ${formatter.formatCurrency(item.amount, 2)}`;
                      const valueSent = `${availableCurrencies[item.ccy_type_id]} ${formatter.formatCurrency(item.amount_sent, 2)}`;
                      return {
                        id: item.id.toString(),
                        date,
                        value,
                        valueSent,
                        status: item.status_code,
                        _key: item.id.toString(),
                      };
                    }),
                    loading: fiatWithdrawHistory.isLoading,
                    whenNoData: (
                      <Empty title="No cash withdrawals" description="You haven't made any cash withdrawals yet." />
                    ),
                  }}
                />
              )}
              <Pagination total={fiatWithdrawHistory.resolvedData?.total || 0} actions={fiatWithdrawPagination} />
            </Card>
          </div>
        </PageSections>
      ) : undefined}
    </PageHolder>
  );
};
