import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import Select from 'react-select';

import { Text, TypographyVariant } from '@aircarbon/ui';
import { helpers, projects } from '@aircarbon/utils-common';

import useTokenTypes from 'hooks/useTokenTypes';

const { carbonProjectMetadataSchema } = projects;
const { WEB_INDEXER_API } = process.env;

type Props = {
  control: any;
  enabledFilters: Array<string>;
  selectThemeColors: Record<string, any>;
  withTokenType?: boolean;
};

const MetadataFiltersFromIndexer: React.FC<Props> = ({ control, enabledFilters, selectThemeColors, withTokenType }) => {
  const { tokenTypes } = useTokenTypes({ options: { enabled: withTokenType } });
  const fetchRFQFilters = async (url: string) => {
    return fetch(`${url}?${helpers.serialize({ filters: enabledFilters })}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp: Response) => resp.json());
  };

  const { data } = useQuery(`${WEB_INDEXER_API}/inventory-filters`, fetchRFQFilters);
  const filters: any = {};
  if (data) {
    enabledFilters.forEach((filterKey) => {
      const inventoryFilter = Object.keys(data).find((key: string) => key === filterKey);
      if (inventoryFilter) {
        // Get metadataSchema to order filter options
        const filterSchema = carbonProjectMetadataSchema.find((schema: { key: string }) => schema.key === filterKey);
        let sortedValues = data[inventoryFilter];
        if (filterSchema?.options) {
          const options = filterSchema.options;
          if (filterKey === 'LIST_COUNTRY') {
            sortedValues = data[inventoryFilter].sort((a: string, b: string) => {
              return (
                options.findIndex((option: { label: string }) => option.label === projects.countryNames[a]) -
                options.findIndex((option: { label: string }) => option.label === projects.countryNames[b])
              );
            });
          } else if (filterKey === 'TXT_BEZERO_RATING') {
            sortedValues = data[inventoryFilter].sort((a: string, b: string) => {
              return (
                options.findIndex((option: { label: string }) => option.label === projects.bezeroRatings[a]) -
                options.findIndex((option: { label: string }) => option.label === projects.bezeroRatings[b])
              );
            });
          } else {
            sortedValues = data[inventoryFilter].sort((a: string, b: string) => {
              return (
                options.findIndex((option: { label: string }) => option.label === a) -
                options.findIndex((option: { label: string }) => option.label === b)
              );
            });
          }
        } else {
          sortedValues = sortedValues.sort();
        }
        filters[filterKey] = sortedValues
          .filter((value: any) => value !== 'undefined')
          .map((value: any) => {
            let label = String(value);
            if (filterKey === 'LIST_COUNTRY') {
              label = projects.countryNames[value];
            }
            if (filterKey === 'TXT_BEZERO_RATING') {
              label = projects.bezeroRatings[value] ?? label;
            }

            return { label, value };
          });
      }
    });
  }

  return (
    <>
      {filters &&
        Object.keys(filters).map((key, index: number) => (
          <div key={`filter-div-${key}`}>
            <div className="flex flex-col gap-xs">
              <Text variant={TypographyVariant.body2}>{projects.projectLabel[key]}</Text>
              <Controller
                name={`metaData.${key}` as any}
                key={`controller-${key}`}
                control={control}
                render={({ field: { name, onChange, onBlur, ref } }) => (
                  <Select
                    name={name}
                    className="select"
                    isMulti
                    options={filters[key]}
                    onChange={(selected) => onChange(selected)}
                    onBlur={onBlur}
                    ref={ref}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      borderWidth: 2,
                      colors: {
                        ...theme.colors,
                        ...selectThemeColors,
                      },
                    })}
                  />
                )}
              />
            </div>
          </div>
        ))}
      {withTokenType && tokenTypes && (
        <div className={`mb-2 w-1/2`}>
          <div className="flex flex-col gap-xs">
            <Text variant={TypographyVariant.body2}>Asset Type</Text>
            <Controller
              name="tokenType"
              control={control}
              render={({ field: { onChange, ref } }) => (
                <Select
                  required
                  className="select"
                  options={
                    tokenTypes.map((tokType) => {
                      const name = `${tokType.fullName} (${tokType.symbol})`;
                      return {
                        label: name,
                        value: tokType.id,
                      };
                    }) ?? []
                  }
                  onChange={(selected) => onChange(selected)}
                  ref={ref}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    borderWidth: 2,
                    colors: {
                      ...theme.colors,
                      ...selectThemeColors,
                    },
                  })}
                />
              )}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MetadataFiltersFromIndexer;
