import { formatter } from '@aircarbon/utils-common';

import CopyInput from 'components/CopyInput';
import { AddressLink } from 'components/styled/Styled';

import { UI } from 'state/ui';

import { BidTable, BidTitle } from '../Styled';
import { TableWrapper } from './styled';

const { truncateMiddle } = formatter;
interface Props {
  rfq: {
    id: number;
    type: string;
    tokenTypeId: number;
    ccyTypeId: number;
    quantity: number;
    filled: number;
    price: number;
    createdAt: Date;
    userId: number;
    __rfqRequestDetails__: Array<any>;
    __rfqResponses__: Array<any>;
  };
  userId?: number;
}

const RFQResponses = ({ rfq, userId }: Props) => {
  const { screenSize } = UI.useContainer();
  let responses = rfq?.__rfqResponses__;
  // If userId sent, means current user is not the BID owner. Is a user matching the Bid.
  if (userId) responses = responses.filter((response) => response.userId === userId);

  const done = responses.filter((item) => item.status === 'DONE');
  const pending = responses.filter((item) => item.status === 'PENDING');
  const failed = responses.filter((item) => item.status === 'FAILED');

  return (
    <div className="mb-8">
      <BidTitle className="mb-4">{userId ? 'My' : ''} Fills</BidTitle>
      {responses?.length === 0 ? (
        <div className="mt-4">No fills yet.</div>
      ) : (
        <TableWrapper>
          <BidTable screenSizes={screenSize}>
            <thead>
              <tr>
                <th>Trade ID (click to view)</th>
                <th>Quantity</th>
                <th>Status</th>
              </tr>
            </thead>
            {pending.length > 0 && (
              <tbody>
                {pending?.map((response) => (
                  <tr key={response.id}>
                    <td>
                      <CopyInput text={response.txHash}>
                        <span className="innerLabel">TX</span>
                        <AddressLink to={`${window.location.origin}/explore/tx/${response.txHash}`}>
                          {truncateMiddle(response.txHash, 16)}
                        </AddressLink>
                      </CopyInput>
                    </td>
                    <td>
                      {formatter.formatNumber(response.quantity, 0)} {response.quantityUnit}
                    </td>
                    <td>{response.status}</td>
                  </tr>
                ))}
              </tbody>
            )}
            {done.length > 0 && (
              <tbody>
                {done?.map((response) => (
                  <tr key={response.id}>
                    <td>
                      <CopyInput text={response.txHash}>
                        <span className="innerLabel">TX</span>
                        <AddressLink to={`${window.location.origin}/explore/tx/${response.txHash}`}>
                          {truncateMiddle(response.txHash, 16)}
                        </AddressLink>
                      </CopyInput>
                    </td>
                    <td>{formatter.formatNumber(response.quantity, 0)}</td>
                    <td>{response.status}</td>
                  </tr>
                ))}
              </tbody>
            )}
            {failed.length > 0 && (
              <tbody>
                {failed?.map((response) => (
                  <tr key={response.id}>
                    <td>
                      <CopyInput text={response.txHash}>
                        <span className="innerLabel">TX</span>
                        <AddressLink to={`${window.location.origin}/explore/tx/${response.txHash}`}>
                          {truncateMiddle(response.txHash, 16)}
                        </AddressLink>
                      </CopyInput>
                    </td>
                    <td>{formatter.formatNumber(response.quantity, 0)}</td>
                    <td>
                      {response.status} ({response.statusMessage})
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </BidTable>
        </TableWrapper>
      )}
    </div>
  );
};

export default RFQResponses;
