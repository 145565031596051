import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { Input } from 'refreshed-component/atoms/Input';
import { ConfirmModal } from 'refreshed-component/molecules/ConfirmModal';
import { RadioBoxList } from 'refreshed-component/molecules/RadioBoxList';
import { toast } from 'refreshed-component/molecules/toast';
import * as yup from 'yup';

import { Button, ButtonType, Text, TextAs, TextColor, TypographyVariant, styled } from '@aircarbon/ui';
import { AssetCategory, FeeType, formatter, hooks } from '@aircarbon/utils-common';
import { logger } from '@aircarbon/utils-common';

import type { User as UserType } from 'pages/account/block-trade/types';
import type { CarbonProject } from 'pages/account/carbon/types';
import { usePairs } from 'pages/account/trading/hooks';
import { useExternalBrokerClient } from 'pages/account/trading/hooks/useExternalBrokerClient';
import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';
import type { AssetCategoryCode } from 'pages/account/trading/layouts/trading.hook';

import { Account } from 'state/account';
import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useDebounce from 'hooks/useDebounce';
import useFee from 'hooks/useFee';
import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { convertTextNumberToValue, getRoleName } from 'utils/helpers';

import { AddCarbonProject } from './AddCarbonProject';

const toSchema = (minimumQty: number) =>
  yup.object().shape({
    sellerId: yup.string().when(['isSellerExternal'], {
      is: (isSellerExternal: boolean) => !isSellerExternal,
      then: (schema) => schema.notOneOf(['none'], 'Required').required('Required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    buyerId: yup.string().when(['isBuyerExternal'], {
      is: (isBuyerExternal: boolean) => !isBuyerExternal,
      then: (schema) => schema.notOneOf(['none'], 'Required').required('Required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    externalSellerId: yup.string().when(['isSellerExternal'], {
      is: (isSellerExternal: boolean) => isSellerExternal,
      then: (schema) => schema.notOneOf(['none'], 'Required').required('Required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    externalBuyerId: yup.string().when(['isBuyerExternal'], {
      is: (isBuyerExternal: boolean) => isBuyerExternal,
      then: (schema) => schema.notOneOf(['none'], 'Required').required('Required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    pairId: yup.string().required('Required'),
    quantity: yup
      .number()
      .transform(function transformQtyUnit(value, originalValue) {
        if (this.isType(value)) return value;
        return convertTextNumberToValue(originalValue);
      })
      .min(minimumQty || 1)
      .required('Required'),
    price: yup
      .number()
      .transform(function transformQtyUnit(value, originalValue) {
        if (this.isType(value)) return value;
        return convertTextNumberToValue(originalValue);
      })
      .positive()
      .required('Required'),
    carbonProjectId: yup.string().when(['hasProject'], {
      is: (hasProject: boolean) => hasProject,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    vintageYear: yup.number().when(['hasProject'], {
      is: (hasProject: boolean) => hasProject,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

const years = () => {
  const startYear = 2010;
  const currentYear = new Date().getFullYear();
  return Array(currentYear - startYear + 1)
    .fill(1)
    .map((_, i) => startYear + i);
};

export type TradeRequestFormData = {
  buyerId?: number;
  sellerId?: number;
  externalSellerId?: number;
  externalBuyerId?: number;
  quantity: number;
  price: number;
  base: number;
  pairId: number;
  carbonProjectId: number | undefined;
  vintageYear: string;
  hasProject: boolean;
  arrangerFeePerThousand?: number;
  isSellerExternal?: boolean;
  isBuyerExternal?: boolean;
};

type Props = {
  clientList: UserType[];
};

const Wrapper = styled.div`
  max-width: 704px;
  width: 100%;
`;

export function CreateRequestForm({ clientList }: Props) {
  const { getSetting } = UI.useContainer();
  const {
    selector: { mainCcyCode, mainCcyNumDecimals, mainCcySymbol },
  } = Entity.useContainer();
  const { product } = useMarketplaceProduct();

  const {
    status: { isMarketUser, canSeeExternalBrokerClientForBlockTrade },
    selector: { getAuthToken, getUserProfile },
    helpers: { memberClientUserTypes },
  } = User.useContainer();

  const {
    selector: { isMarketOpen },
  } = useMarketSettings({});

  const minLotQty = Number(
    getSetting(Number(product) === AssetCategory.rec ? 'web_blockTrade_minLotQty_rec' : 'web_blockTrade_minLotQty'),
  );

  const blockTradeDisclaimer = getSetting(
    Number(product) === Number(AssetCategory.token)
      ? 'web_settings_blockTrade_carbon_disclaimer'
      : 'web_settings_blockTrade_disclaimer',
  );
  const acceptTimeLimitText = getSetting('blockTrade_acceptTimeLimitText');
  const acceptGuideName = getSetting('blockTrade_acceptGuideName');
  const blockTradeLabel = getSetting('web_settings_block_trade_label') ?? 'Block Trades';

  const [saving, setSaving] = useState(false);
  const [confirm, setConfirm] = useState<TradeRequestFormData | undefined>(undefined);
  const [pairId, setPairId] = useState<number | undefined>();
  const { pairs, pairsSelectOptions: pairsOptions } = usePairs({
    filterByMarket: 'block',
    assetCategories: [AssetCategory[Number(product)]] as Array<AssetCategoryCode>,
  });
  const previousPairsOptions = hooks.usePrevious(pairsOptions);
  const selectedPair = pairId ? pairs?.find((pair) => pair.id === pairId) : undefined;
  const minimumQty = minLotQty * (selectedPair?.baseAsset?.lotQtySize ?? 1);

  const formProperties = useForm<TradeRequestFormData>({
    resolver: yupResolver(toSchema(minimumQty)),
  });

  const user = getUserProfile();

  const { memberClientAccounts } = Account.useContainer();

  const {
    trigger,
    watch,
    reset,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
  } = formProperties;

  const {
    buyerId,
    sellerId,
    externalBuyerId,
    externalSellerId,
    quantity,
    price,
    vintageYear,
    carbonProjectId,
    hasProject,
    arrangerFeePerThousand,
    isSellerExternal,
    isBuyerExternal,
  } = watch();

  const [tokenQty, setTokenQty] = useState(0);
  const debouncedTokenQty = useDebounce(tokenQty, 500);
  const debouncedPrice = useDebounce(price, 500);

  const { feeAmount, isLoading: isLoadingFeeAmount } = useFee({
    params: {
      feeType: FeeType.TRADE_BLOCK_BROKER_FEE,
      assetCategoryId: Number(product),
      tokenQty: debouncedTokenQty,
      totalAmount: debouncedTokenQty * debouncedPrice,
    },
    options: { enabled: debouncedTokenQty > 0 && debouncedPrice > 0 },
  });
  const { data: externalClientList } = useExternalBrokerClient({ enabled: !!isSellerExternal || !!isBuyerExternal });

  logger.info({
    buyerId,
    sellerId,
    quantity,
    price,
    pairId,
    hasProject,
    carbonProjectId,
    vintageYear,
  });

  const oboAccounts = memberClientAccounts?.filter((account) => memberClientUserTypes.includes(account.account_type));

  const clientsOptions = [
    ...(clientList?.map((user) => ({
      label: `${user.parent ? `${user.parent.firstName} ${user.parent.lastName} / ` : ''}${user.firstName} ${
        user.lastName
      }`,
      id: user.id,
    })) || []),
    ...(oboAccounts?.map((user) => ({
      label: `${user.first_name} ${user.last_name} (${getRoleName({ accountType: user.account_type }).short})`,
      id: user.user_id,
    })) || []),
  ];

  if (isMarketUser()) {
    clientsOptions.unshift({ label: `${user?.first_name} ${user?.last_name}`, id: user?.user_id });
  }

  const externalClientOptions =
    externalClientList?.map((item) => ({
      label: item.name,
      id: item.id,
    })) || [];

  const onSubmit = useCallback(
    (fromData: TradeRequestFormData) => {
      if (buyerId === sellerId) {
        toast.error(`Buyer and Seller should not be same.`);
        return;
      }
      setConfirm(fromData);
    },
    [buyerId, sellerId],
  );

  const onSelectCarbonProject = (project: CarbonProject) => {
    setValue('carbonProjectId', project.id);
    setValue('hasProject', true);
  };

  const onRemoveCarbonProject = () => {
    setValue('carbonProjectId', undefined);
    setValue('hasProject', false);
  };

  const createTradeRequest = async ({
    buyerId,
    sellerId,
    quantity,
    price,
    carbonProjectId,
    vintageYear,
    arrangerFeePerThousand,
  }: TradeRequestFormData) => {
    setSaving(true);
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    const response = await fetch(`/api/user/block-trade/request`, {
      method: 'POST',
      body: JSON.stringify({
        buyerUserId: Number(buyerId),
        sellerUserId: Number(sellerId),
        externalBuyerId: Number(externalBuyerId),
        externalSellerId: Number(externalSellerId),
        quantity: Number(quantity),
        price: Number(price),
        pairId: Number(selectedPair?.id),
        carbonProjectId: Number(carbonProjectId),
        vintageYear: Number(vintageYear),
        arrangerFeePerThousand: Number(arrangerFeePerThousand),
      }),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });
    if (response.ok) {
      toast.success(`Trade request is created successfully.`);
      reset();
      setConfirm(undefined);
    } else {
      const result = await response.json();
      toast.error(result.message || response.statusText);
    }
    setSaving(false);
  };

  const trySubmit = async () => {
    const isValid = await trigger();
    if (isValid === true) {
      onSubmit(getValues());
    }
  };

  useEffect(() => {
    if (pairsOptions === previousPairsOptions) {
      return;
    }
    setPairId(pairsOptions?.[0]?.value);
    setValue('pairId', pairsOptions?.[0]?.value);
  }, [pairsOptions, previousPairsOptions]);

  const total = quantity * price;
  const tradeFee = feeAmount;
  const arrangerFee = arrangerFeePerThousand ? (Number(quantity) * Number(arrangerFeePerThousand)) / 1000 : 0;
  const tokenUnit = selectedPair?.baseAsset?.uom?.code;

  return (
    <div className="flex justify-center w-full">
      <Wrapper>
        <div className="bg-gray_0 rounded-large mt-large">
          <div className="border-b border-gray_200 p-large">
            <Text variant={TypographyVariant.h6Title}>New Trade Request</Text>
          </div>
          <div className="p-large">
            <form className="flex flex-col gap-base" onSubmit={handleSubmit(trySubmit)}>
              {canSeeExternalBrokerClientForBlockTrade() && (
                <div className="flex flex-col gap-xs">
                  <Text>External Client Trade</Text>
                  <RadioBoxList
                    onClick={(item) => {
                      const type = item.id;
                      if (type === 'buyer') {
                        setValue('isBuyerExternal', true);
                        setValue('isSellerExternal', false);
                      } else if (type === 'seller') {
                        setValue('isBuyerExternal', false);
                        setValue('isSellerExternal', true);
                      } else {
                        setValue('isBuyerExternal', false);
                        setValue('isSellerExternal', false);
                      }
                    }}
                    selected={getValues('isBuyerExternal') ? 'buyer' : getValues('isSellerExternal') ? 'seller' : 'no'}
                    list={[
                      {
                        id: 'no',
                        label: 'No',
                      },
                      {
                        id: 'buyer',
                        label: 'Buyer',
                      },
                      {
                        id: 'seller',
                        label: 'Seller',
                      },
                    ]}
                  />
                </div>
              )}
              <div className="flex flex-col w-full sm:flex-row gap-base">
                <div className="flex flex-col flex-1 gap-base">
                  <div className="flex flex-col gap-xs">
                    <Text variant={TypographyVariant.body2}>Buyer</Text>
                    <Dropdown
                      list={isBuyerExternal ? externalClientOptions : clientsOptions}
                      selected={[Number(isBuyerExternal ? getValues('externalBuyerId') : getValues('buyerId'))]}
                      onSelectItem={(list) => {
                        if (isBuyerExternal) {
                          setValue('externalBuyerId', Number(list?.id), {
                            shouldValidate: true,
                          });
                          setValue('buyerId', undefined, {
                            shouldValidate: true,
                          });
                        } else {
                          setValue('externalBuyerId', undefined, {
                            shouldValidate: true,
                          });
                          setValue('buyerId', Number(list?.id), {
                            shouldValidate: true,
                          });
                        }
                      }}
                      config={{
                        color: 'gray',
                        size: 'base',
                      }}
                      placeholder="Select account"
                    />
                    {(isBuyerExternal ? errors.externalBuyerId?.message : errors.buyerId?.message) && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {isBuyerExternal ? errors.externalBuyerId?.message : errors.buyerId?.message}
                      </Text>
                    )}
                  </div>
                </div>
                <div className="flex flex-col flex-1 gap-base">
                  <div className="flex flex-col gap-xs">
                    <Text variant={TypographyVariant.body2}>Seller</Text>
                    <Dropdown
                      list={isSellerExternal ? externalClientOptions : clientsOptions}
                      selected={[isSellerExternal ? getValues('externalSellerId') : getValues('sellerId')]}
                      onSelectItem={(list) => {
                        if (isSellerExternal) {
                          setValue('externalSellerId', Number(list?.id), {
                            shouldValidate: true,
                          });
                          setValue('sellerId', undefined, {
                            shouldValidate: true,
                          });
                        } else {
                          setValue('externalSellerId', undefined, {
                            shouldValidate: true,
                          });
                          setValue('sellerId', Number(list?.id), {
                            shouldValidate: true,
                          });
                        }
                      }}
                      config={{
                        color: 'gray',
                        size: 'base',
                      }}
                      placeholder="Select account"
                    />
                    {(isSellerExternal ? errors.externalSellerId?.message : errors.sellerId?.message) && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {isSellerExternal ? errors.externalSellerId?.message : errors.sellerId?.message}
                      </Text>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full sm:flex-row gap-base">
                <div className="flex flex-col flex-1 gap-xs">
                  <Text variant={TypographyVariant.body2}>Quantity*</Text>
                  <Input
                    config={{
                      color: 'gray',
                      size: 'base',
                      validation: {
                        type: 'float',
                        numeralDecimalScale: 2,
                        numeralPositiveOnly: true,
                        numeralThousandsGroupStyle: 'thousand',
                      },
                    }}
                    value={getValues('quantity')}
                    onChange={(event) => {
                      setTokenQty(convertTextNumberToValue((event?.target as any)?.rawValue));
                      setValue('quantity', (event?.target as any)?.rawValue || undefined, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <Text variant={TypographyVariant.body2}>
                    Min {formatter.formatNumber(minimumQty, 0)} {tokenUnit}
                  </Text>
                  {errors.quantity?.message && (
                    <Text variant={TypographyVariant.body2} color={TextColor.error}>
                      {errors.quantity.message}
                    </Text>
                  )}
                </div>
                <div className="flex flex-col flex-1 gap-xs">
                  <Text variant={TypographyVariant.body2}>
                    Price per {tokenUnit} ({mainCcySymbol}) *
                  </Text>
                  <Input
                    config={{
                      color: 'gray',
                      size: 'base',
                      validation: {
                        type: 'float',
                        numeralDecimalScale: 2,
                        numeralPositiveOnly: true,
                        numeralThousandsGroupStyle: 'thousand',
                      },
                    }}
                    value={getValues('price')}
                    onChange={(event) => {
                      setValue('price', (event?.target as any)?.rawValue || undefined, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  {errors.price?.message && (
                    <Text variant={TypographyVariant.body2} color={TextColor.error}>
                      {errors.price.message}
                    </Text>
                  )}
                </div>
                <div className="flex flex-col flex-1 gap-xs">
                  <Text variant={TypographyVariant.body2}>Arranger Fee Per 1,000 {tokenUnit}</Text>
                  <Input
                    config={{
                      color: 'gray',
                      size: 'base',
                      validation: {
                        type: 'float',
                        numeralDecimalScale: 2,
                        numeralPositiveOnly: true,
                        numeralThousandsGroupStyle: 'thousand',
                      },
                    }}
                    value={getValues('arrangerFeePerThousand')}
                    onChange={(event) => {
                      setValue('arrangerFeePerThousand', (event?.target as any)?.rawValue || undefined, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  {errors.arrangerFeePerThousand?.message && (
                    <Text variant={TypographyVariant.body2} color={TextColor.error}>
                      {errors.arrangerFeePerThousand.message}
                    </Text>
                  )}
                </div>
              </div>
              <div className="flex flex-col w-full border-t border-gray_100 gap-base" />
              <div className="flex flex-col w-full gap-base">
                <div className="flex flex-col gap-xs">
                  <Text variant={TypographyVariant.body2}>Contract</Text>
                  <Dropdown
                    list={pairsOptions.map(({ value, label }) => ({ id: value, label }))}
                    selected={[getValues('pairId')]}
                    onSelectItem={(list) => {
                      setPairId(Number(list?.id));
                      setValue('pairId', (list?.id as any) || undefined, {
                        shouldValidate: true,
                      });
                    }}
                    config={{
                      color: 'gray',
                      size: 'base',
                    }}
                    placeholder="Select account"
                  />
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    {blockTradeDisclaimer}
                  </Text>
                  {errors.pairId?.message && (
                    <Text variant={TypographyVariant.body2} color={TextColor.error}>
                      {errors.pairId?.message}
                    </Text>
                  )}
                </div>
                <div className="flex flex-col gap-xs">
                  <Text variant={TypographyVariant.body2}>Project (optional)</Text>
                  <AddCarbonProject
                    assetCategoryId={Number(product) as AssetCategory}
                    onChange={(project) => {
                      project ? onSelectCarbonProject(project) : onRemoveCarbonProject();
                    }}
                  />
                </div>
                {hasProject && (
                  <div className="flex flex-col gap-xs">
                    <Text variant={TypographyVariant.body2}>Vintage Year</Text>
                    <Dropdown
                      list={
                        years()?.map((year: number) => ({
                          label: year,
                          id: year,
                        })) || []
                      }
                      selected={[getValues('vintageYear')]}
                      onSelectItem={(list) => {
                        setValue('vintageYear', (list?.id as any) || undefined, {
                          shouldValidate: true,
                        });
                      }}
                      config={{
                        color: 'gray',
                        size: 'base',
                      }}
                      placeholder="Select account"
                    />
                    {errors.vintageYear?.message && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {errors.vintageYear?.message}
                      </Text>
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-col w-full border border-gray_200 rounded-medium">
                <div className="flex flex-row flex-1 justify-between p-base">
                  <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                    Trade Amount:
                  </Text>
                  <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                    {Number(quantity) && Number(price)
                      ? `${mainCcyCode}${formatter.formatNumber(total, mainCcyNumDecimals)} ${mainCcySymbol}`
                      : `${mainCcyCode}0.00 ${mainCcySymbol}`}
                  </Text>
                </div>
                <div className="flex flex-row flex-1 justify-between bg-gray_50 p-base">
                  <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                    Fee:
                  </Text>
                  <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                    {Number(quantity) && Number(price)
                      ? `${mainCcyCode}${formatter.formatNumber(tradeFee, mainCcyNumDecimals)} ${mainCcySymbol}`
                      : `${mainCcyCode}0.00 ${mainCcySymbol}`}
                  </Text>
                </div>
                <div className="flex flex-row flex-1 justify-between p-base">
                  <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                    Arranger Fee:
                  </Text>
                  <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                    {Number(quantity) && Number(price)
                      ? `${mainCcyCode}${formatter.formatNumber(arrangerFee, mainCcyNumDecimals)} ${mainCcySymbol}`
                      : `${mainCcyCode}0.00 ${mainCcySymbol}`}
                  </Text>
                </div>
                <div className="flex flex-row flex-1 justify-between bg-gray_50 p-base">
                  <Text variant={TypographyVariant.subtitle2}>Buyer pays:</Text>
                  <Text as={TextAs.span} variant={TypographyVariant.subtitle2}>
                    {Number(quantity) && Number(price)
                      ? `${mainCcyCode}${formatter.formatNumber(
                          total + tradeFee + arrangerFee,
                          mainCcyNumDecimals,
                        )} ${mainCcySymbol}`
                      : `${mainCcyCode}0.00 ${mainCcySymbol}`}
                  </Text>
                </div>
                <div className="flex flex-row flex-1 justify-between p-base">
                  <Text variant={TypographyVariant.subtitle2}>Seller receives:</Text>
                  <Text as={TextAs.span} variant={TypographyVariant.subtitle2}>
                    {Number(quantity) && Number(price)
                      ? `${mainCcyCode}${formatter.formatNumber(
                          total - tradeFee - arrangerFee,
                          mainCcyNumDecimals,
                        )} ${mainCcySymbol}`
                      : `${mainCcyCode}0.00 ${mainCcySymbol}`}
                  </Text>
                </div>
              </div>
              <div className="flex flex-col w-full gap-base">
                <div className="flex flex-col gap-xs">
                  {!isMarketOpen() && (
                    <Text variant={TypographyVariant.body2} color={TextColor.error}>
                      Market is Closed
                    </Text>
                  )}
                  <Button
                    isLoading={saving || isLoadingFeeAmount}
                    isDisabled={(errors && Object.keys(errors).length > 0) || !isMarketOpen()}
                    type={ButtonType.Button}
                    onPress={() => {
                      trySubmit().then();
                    }}
                  >
                    Submit Trade Request
                  </Button>
                </div>
              </div>
            </form>
            {(confirm || saving) && (
              <ConfirmModal
                isLoading={saving}
                isOpen={true}
                title="Confirm Trade Request"
                onClose={() => setConfirm(undefined)}
                action={undefined}
                accept={{
                  label: 'Accept and Submit',
                  callback() {
                    if (confirm) createTradeRequest(confirm);
                  },
                }}
              >
                <div className="contents">
                  <div className="flex flex-col gap-large">
                    {acceptTimeLimitText && (
                      <>
                        <Text>
                          For {blockTradeLabel} submitted via the Block Portal, the relevant parties conclude the
                          Transaction when relevant sufficient Funds or Digital Registry Receipts are deposited in their
                          Participant Account or Client Account, which must be no later than {acceptTimeLimitText}{' '}
                          following the initial submission.
                        </Text>
                        <Text>
                          If any party has insufficient balance in its account after the expiry of such period of{' '}
                          {acceptTimeLimitText}, the Block Trade cannot be accepted by the relevant parties and will be
                          cancelled.
                        </Text>
                      </>
                    )}
                    {acceptGuideName && <Text>{acceptGuideName}</Text>}
                  </div>
                </div>
              </ConfirmModal>
            )}
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

<div className="flex justify-center w-full">
  <Wrapper>
    <div className="bg-gray_0 rounded-large mt-large">
      <div className="border-b border-gray_200 p-large">
        <Text variant={TypographyVariant.h6Title}>New Trade Request</Text>
      </div>
      <div className="p-large">
        <div className="flex flex-col gap-xs">
          <Text>External Client Trade</Text>
          <RadioBoxList
            list={[
              {
                id: 1,
                label: 'Seller',
              },
              {
                id: 2,
                label: 'Buyer',
              },
            ]}
          />
        </div>
      </div>
    </div>
  </Wrapper>
</div>;
