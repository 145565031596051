import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import {
  BorderColor,
  Layer,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
} from '@aircarbon/ui';

import Loading from './Loading';

const Wrapper = styled.div`
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  animation-duration: 500ms;
  animation-name: openWindow;
  @keyframes openWindow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes closeWindow {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  &.open {
    opacity: 1;
    animation-name: openWindow;
  }
  &.closing,
  &.hide {
    opacity: 0;
    animation-name: closeWindow;
  }

  @keyframes reOpen {
    from {
      opacity: 0;
      scale: 0.9;
    }
    to {
      opacity: 1;
      scale: 1;
    }
  }
  @keyframes open {
    from {
      opacity: 0;
      scale: 1.1;
    }
    to {
      opacity: 1;
      scale: 1;
    }
  }
  @keyframes close {
    from {
      opacity: 1;
      scale: 1;
    }
    to {
      opacity: 0;
      scale: 0.9;
    }
  }

  > .modal {
    opacity: 1;
    overflow: auto;
    position: relative;
    border-radius: ${({ theme }) => theme.system.border.radius.m};
    border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    background: ${({ theme }) => toLayerBackground(theme)('layer')};
    max-height: calc(100% - 50px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @media (min-width: 600px) {
      min-width: 500px;
    }
    min-width: calc(100% - 50px);
    max-width: 675px;
    > .header {
      display: flex;
      padding: ${({ theme }) => toSpacing(theme)(12)};
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      border-bottom: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    }
    > form {
      display: contents;
    }
  }

  ${'#modal-root'} & {
    > .modal {
      animation-name: close !important;
      animation-duration: 500ms;
    }

    &:last-child {
      &.open > .modal {
        animation-name: open !important;
      }
      > .modal {
        animation-name: reOpen !important;
      }
      > .modal.closing {
        animation-name: close !important;
      }
      opacity: 1;
    }
  }
`;

export type ModelChildrenParam = {
  onClose: () => void;
  onOpen: () => void;
  onLoading: (loading?: boolean) => void;
};

export type ModelChildren = (param: ModelChildrenParam) => React.ReactNode;

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
  children: ModelChildren;
  isOpen?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  action?: React.ReactElement;
}

export const ModalContent = styled.div`
  padding: ${({ theme }) => toSpacing(theme)(12)};
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const ModalFooter = styled.div`
  padding: ${({ theme }) => toSpacing(theme)(12)};
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
`;

const processList = () => {
  const list = document.getElementById('modal-root')?.childNodes;
  list?.forEach((child, index) => {
    const element = child as HTMLDivElement;
    if (index !== list.length - 1) {
      element.classList.add('hide');
      element.classList.remove('open');
    } else {
      element.classList.remove('hide');
    }
  });
};

/**
 * @deprecated
 * Use `import { Modal } from "@aircarbon/ui"`
 */
export const Modal = ({ title, description, children, action, onClose, isOpen, isLoading, className = '' }: Props) => {
  const [showModel, setShowModel] = useState(false);
  const [closingModel, setClosingModel] = useState(false);
  const [loading, setLoading] = useState(false);

  const reactNode = useMemo(() => {
    return {
      content: (
        <Layer>
          {(isOpen || showModel) && (
            <ModalPortal>
              <Wrapper
                className={`${
                  closingModel ? 'closing' : 'open'
                } flex fixed inset-0 z-50 justify-center items-center w-full h-full main-modal ${className}`}
              >
                <div className={`${closingModel ? 'closing' : ''} modal mx-auto z-50`}>
                  <div className="header">
                    <div className="flex flex-col gap-2xs">
                      <Text variant={TypographyVariant.h6Title}>{title}</Text>
                      {description && (
                        <Text variant={TypographyVariant.subtitle2} color={TextColor.secondary}>
                          {description}
                        </Text>
                      )}
                    </div>
                    <svg
                      onClick={() => {
                        setClosingModel(true);
                        onClose?.();
                      }}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="cursor-pointer"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.29303 4.28966C4.48056 4.10219 4.73487 3.99688 5.00003 3.99688C5.26519 3.99688 5.5195 4.10219 5.70703 4.28966L10 8.58266L14.293 4.28966C14.3853 4.19415 14.4956 4.11797 14.6176 4.06556C14.7396 4.01315 14.8709 3.98557 15.0036 3.98441C15.1364 3.98326 15.2681 4.00856 15.391 4.05884C15.5139 4.10912 15.6255 4.18338 15.7194 4.27727C15.8133 4.37116 15.8876 4.48281 15.9379 4.60571C15.9881 4.72861 16.0134 4.86029 16.0123 4.99306C16.0111 5.12584 15.9835 5.25706 15.9311 5.37907C15.8787 5.50107 15.8025 5.61142 15.707 5.70366L11.414 9.99666L15.707 14.2897C15.8892 14.4783 15.99 14.7309 15.9877 14.9931C15.9854 15.2553 15.8803 15.5061 15.6948 15.6915C15.5094 15.8769 15.2586 15.9821 14.9964 15.9843C14.7342 15.9866 14.4816 15.8858 14.293 15.7037L10 11.4107L5.70703 15.7037C5.51843 15.8858 5.26583 15.9866 5.00363 15.9843C4.74143 15.9821 4.49062 15.8769 4.30521 15.6915C4.1198 15.5061 4.01463 15.2553 4.01236 14.9931C4.01008 14.7309 4.11087 14.4783 4.29303 14.2897L8.58603 9.99666L4.29303 5.70366C4.10556 5.51614 4.00024 5.26183 4.00024 4.99666C4.00024 4.7315 4.10556 4.47719 4.29303 4.28966Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                  </div>
                  {children({
                    onOpen: () => {
                      setShowModel(true);
                      processList();
                    },
                    onClose: () => {
                      setClosingModel(true);
                      onClose?.();
                      processList();
                    },
                    onLoading: (loading?: boolean) => {
                      setLoading(!!loading);
                    },
                  })}
                  {(isLoading || loading) && <Loading backgroundAlpha={true} />}
                </div>
              </Wrapper>
            </ModalPortal>
          )}
        </Layer>
      ),
      children: action ? (
        <>
          {{
            ...action,
            props: {
              ...action.props,
              onClick: () => {
                setShowModel(true);
                action.props.onClick?.();
              },
              onPress: () => {
                setShowModel(true);
                action.props.onClick?.();
              },
            },
          }}
        </>
      ) : (
        <></>
      ),
    };
  }, [isOpen, showModel, closingModel, title, description, children, isLoading, loading, action, onClose]);

  useLayoutEffect(() => {
    processList();
  }, [isOpen, showModel]);

  useEffect(() => {
    if (closingModel)
      setTimeout(() => {
        setShowModel(false);
        setClosingModel(false);
      }, 500);
  }, [closingModel]);

  return (
    <>
      {reactNode.content}
      {reactNode.children}
    </>
  );
};

export default Modal;

const ModalPortal = (props: any) => {
  const modalRoot = document.getElementById('modal-root');
  /**
   * Issue #2: Cannot nest forms directly in DOM
   * https://html.spec.whatwg.org/multipage/forms.html#the-form-element
   * This is a basic html spec, the fix is using portals to unest Modals
   * https://reactjs.org/docs/portals.html
   */
  return createPortal(props.children, modalRoot!);
};
