import { BorderColor, Text, TextColor, TypographyVariant, styled, toBorderColor } from '@aircarbon/ui';

type TabItem = {
  value: string;
  label: string;
};

export const FilterTabs: React.FC<{
  items: Array<TabItem>;
  activeTab: string;
  onPressTab: (value: string) => void;
}> = (props) => {
  const { items, activeTab, onPressTab } = props;
  return (
    <StyledFilterTabs>
      {items.map((item, index) => (
        <Tab isActive={item.value === activeTab} key={index} onPress={() => onPressTab(item.value)}>
          {item.label}
        </Tab>
      ))}
    </StyledFilterTabs>
  );
};

const Tab: React.FC<{
  isActive?: boolean;
  onPress(): void;
}> = (props) => {
  const { children, isActive = false, onPress } = props;

  return (
    <StyledTab hasBorder={isActive} onClick={onPress}>
      <Text variant={TypographyVariant.subtitle1} color={isActive ? TextColor.primary : TextColor.secondary}>
        {children}
      </Text>
    </StyledTab>
  );
};

const StyledTab = styled.div<{
  hasBorder: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: -2px;
  border-bottom: 2px solid
    ${({ theme, hasBorder }) => (hasBorder ? toBorderColor(theme)(BorderColor.neutral) : 'transparent')};
  cursor: pointer;
`;

const StyledFilterTabs = styled.div`
  height: 36px;
  margin-top: 16px;
  display: flex;
  gap: 32px;
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  flex-shrink: 0;
`;
