import { Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Text, TextColor, TypographyVariant } from '@aircarbon/ui';

export const ProgressBar: React.FC<{
  /**
   * @example 'File name.pdf' or 'Preparing documents'
   */
  label: string;

  /**
   * @example '59%' or '1/15'
   */
  value: string;

  /**
   * Percentage value of the progress bar
   */
  progress: number;

  /**
   * Additional styling for the container
   */
  className?: string;
}> = (props) => {
  const { label, value, progress, className } = props;

  return (
    <StyledProgressBar className={className}>
      <Head>
        <Text variant={TypographyVariant.subtitle2}>{label}</Text>
        <Text variant={TypographyVariant.subtitle2} color={TextColor.secondary}>
          {value}
        </Text>
      </Head>
      <Progress>
        <FillerBar percentage={progress} />
      </Progress>
    </StyledProgressBar>
  );
};

const StyledProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Progress = styled.div`
  overflow: hidden;
  height: 6px;
  border-radius: 2px;
  background: var(${Colors.gray_200});
  width: 100%;
`;

const FillerBar = styled.div<{
  percentage: number;
}>`
  height: 100%;
  border-radius: 2px;
  background: var(${Colors.gray_900});
  width: ${({ percentage }) => `${percentage}%`};
`;
