import { Text } from 'refreshed-component/atoms/Text';
import { type Colors, FontSize } from 'refreshed-component/design-system';

import { Icon, IconName, Tooltip } from '@aircarbon/ui';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import Loading from './Loading';

export const MarketStatus = () => {
  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({
    includeMarketStatus: true,
  });

  if (isLoadingMarketSettings) return <Loading isOverLay={false} size="small" />;

  if (!marketSettings?.marketStatusObj?.label) {
    return <></>;
  }

  return (
    <>
      <Text size={FontSize.small}>Market Status:</Text>
      {/* TODO: make a new colors map for marketStatusObj?.color */}
      <Text
        color={marketSettings?.marketStatusObj?.color as Colors}
        size={FontSize.small}
        postfix={{
          view: marketSettings?.marketStatusObj?.description ? (
            <Tooltip value={marketSettings?.marketStatusObj?.description}>
              <Icon name={IconName.InfoCircleOutlined} />
            </Tooltip>
          ) : undefined,
        }}
      >
        {marketSettings?.marketStatusObj?.label}
      </Text>
    </>
  );
};
