import React, { type ReactElement } from 'react';

import {
  BorderColor,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toBorderColor,
  toLayerBackground,
  toTextColor,
} from '@aircarbon/ui';

export const AssetsAllocationTable: React.FC<{
  assets: Array<{
    assetType: string;
    balance: string;
    packQuantity: string;
    balanceAfter: string;
  }>;
  errors?: Record<string, string>;
}> = (props) => {
  return (
    <StyledAssetsAllocationTable>
      <Row isHeading key="heading">
        <Column>Asset type</Column>
        <Column>Balance</Column>
        <Column>Pack Qty (tCO2)</Column>
        <Column>Balance after (tCO2)</Column>
      </Row>
      {props.assets.map((asset, key) => (
        <Row key={key}>
          <Column>{asset.assetType}</Column>
          <Column>{asset.balance}</Column>
          <Column hasError={!!props.errors?.[`assetsBalances[${key}].balanceChange`]}>{asset.packQuantity}</Column>
          <Column hasError={!!props.errors?.[`assetsBalances[${key}].resultingBalance`]}>{asset.balanceAfter}</Column>
        </Row>
      ))}
    </StyledAssetsAllocationTable>
  );
};

const StyledAssetsAllocationTable = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  overflow: hidden;
`;

const Row: React.FC<
  {
    isHeading?: boolean;
  } & {
    children: Array<
      ReactElement<{
        isHeading?: boolean;
      }>
    >;
  }
> = (props) => {
  const { children, isHeading } = props;

  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (!child || typeof child === 'string' || typeof child === 'number' || typeof child === 'boolean') {
      return child;
    }
    return React.cloneElement<{
      isHeading?: boolean;
    }>(
      child as React.ReactElement<{
        isHeading?: boolean;
      }>,
      {
        isHeading,
      },
    );
  });

  return <StyledRow isHeading={isHeading}>{childrenWithProps}</StyledRow>;
};

const StyledRow = styled.div<{
  isHeading?: boolean;
}>`
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  ${({ isHeading, theme }) =>
    isHeading &&
    `
    border-top: none;
    text-transform: uppercase;
    color: ${toTextColor(theme)(TextColor.secondary)};
    background-color: ${toLayerBackground(theme)('field')}
  `}
`;

const Column: React.FC<{
  isHeading?: boolean;
  hasError?: boolean;
}> = (props) => {
  const { children, isHeading = false, hasError = false } = props;

  return (
    <StyledColumn>
      <Text
        variant={isHeading ? TypographyVariant.subtitle2 : TypographyVariant.body2}
        color={isHeading ? TextColor.secondary : hasError ? TextColor.error : TextColor.primary}
      >
        {children}
      </Text>
    </StyledColumn>
  );
};

const StyledColumn = styled.div`
  flex-grow: 1;
  flex-basis: 33.333%;
  padding: 16px;
`;
