import axios from 'axios';
import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';

import {
  Divider,
  DividerDirection,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toLayerBackground,
} from '@aircarbon/ui';

import Loading from 'components/styled/Loading';

import { User } from 'state/user';

import SimpleBar from '../components/Simplebar';
import { Wrapper } from '../components/styled';

type FeedItem = {
  id: number;
  title: string;
  url: string;
};

const FeedHolder = styled.div`
  width: calc(100% - 10px);
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const FeedView = styled.div`
  width: 100%;
  padding: 10px;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.system.border.radius.m};
  &:hover {
    background-color: ${({ theme }) => toLayerBackground(theme)('fieldAccent')};
  }
`;

let rssParser: any;
function rssParserInstance(): Promise<any> {
  if (!rssParser) {
    return import('rss-parser/dist/rss-parser.min.js').then((module) => {
      rssParser = new module.default();
      return rssParser;
    });
  }

  return Promise.resolve(rssParser);
}

const NewsFeed = () => {
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  const [feedList, setFeedList] = useState<FeedItem[] | undefined>();
  const [feedIndex, setFeedIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [feedData, setFeedData] = useState<{
    items: {
      categories: string[];
      comments: string;
      content: string;
      contentSnippet: string;
      creator: string;
      'dc:creator': string;
      guid: string;
      isoDate: string;
      link: string;
      pubDate: string;
      title: string;
    }[];
  }>();

  useEffect(() => {
    // TODO: Move to fetcher
    async function getRss() {
      const authToken = await getAuthToken();
      axios
        .get('/api/user/rss/list', {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          setFeedList(response.data);
        });
    }
    getRss();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // TODO: Move to fetcher
    async function getRss() {
      if (feedList) {
        const authToken = await getAuthToken();
        setLoading(true);
        axios
          .get(`/api/user/rss/load?id=${feedList[feedIndex].id}`, {
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${authToken}`,
            },
          })
          .then((response) => {
            return rssParserInstance().then((parser) => parser.parseString(response.data));
          })
          .then((data) => {
            setFeedData(data);
            setLoading(false);
          });
      }
    }
    getRss();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedList, feedIndex]);

  return (
    <Wrapper className="flex flex-col w-full h-full">
      <div className="flex relative flex-col flex-auto gap-2 h-full">
        <SimpleBar>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {feedData && (
                <FeedHolder className="gap-3">
                  <Dropdown
                    list={feedList?.map((item, index) => ({
                      id: index.toString(),
                      label: item.title,
                    }))}
                    selected={[feedIndex.toString()]}
                    onSelectItem={(item) => {
                      setFeedIndex(Number(item.id));
                    }}
                    config={{
                      color: 'gray',
                      size: 'sm',
                    }}
                    placeholder="Select"
                  />
                  {feedData.items.map((item, index, list) => {
                    return (
                      <React.Fragment key={`news-item-${index}`}>
                        <FeedView onClick={() => window.open(item.link, '_blank')} className="flex flex-col gap-3">
                          <Text color={TextColor.secondary} variant={TypographyVariant.caption}>
                            {format(new Date(item.isoDate), 'HH:mm:ss MMM d yyyy ')}
                          </Text>
                          <Text variant={TypographyVariant.h5Title}>{item.title}</Text>
                          <Text color={TextColor.secondary}>{item.content}</Text>
                        </FeedView>
                        {list.length !== index + 1 && <Divider direction={DividerDirection.Horizontal} />}
                      </React.Fragment>
                    );
                  })}
                </FeedHolder>
              )}
            </>
          )}
        </SimpleBar>
      </div>
    </Wrapper>
  );
};

export default NewsFeed;
