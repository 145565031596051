import type { ReactNode } from 'react';
import {
  DetailedCard,
  DetailedCardDescriptionItem,
  DetailedCardDescriptionItemWithoutPadding,
} from 'refreshed-component/templates/DetailedCard';

import { Text, TextColor, TypographyVariant, styled } from '@aircarbon/ui';

const BlockRequestCardRoot = styled.div`
  display: contents;
  > .project-item > .children > .description > .controls {
    width: 300px;
  }
`;
export const BlockRequestCard = ({
  title,
  description,
  type = 'light',
  info,
  control,
}: {
  type?: 'light' | 'white';
  title?: string;
  description: string;
  info: {
    onClick?: () => void;
    fields?: {
      name: string;
      value: string | ReactNode;
    }[];
  };
  control: {
    actions?: ReactNode;
    fields?: (
      | {
          name: string;
          value: string | ReactNode;
        }
      | undefined
    )[];
  };
}) => {
  return (
    <BlockRequestCardRoot>
      <DetailedCard type={type} className={`project-item`}>
        <DetailedCardDescriptionItemWithoutPadding
          onClick={info.onClick}
          className={`flex flex-col flex-1 h-auto justify-between overflow-hidden ${
            info.onClick ? 'cursor-pointer' : ''
          }`}
        >
          <div className="flex flex-col w-full h-auto gap-base p-large">
            <div className="flex flex-col w-full gap-small">
              {title && (
                <Text variant={TypographyVariant.subtitle2} color={TextColor.secondary}>
                  {title}
                </Text>
              )}
              {description && <Text variant={TypographyVariant.h5Title}>{description}</Text>}
            </div>
          </div>
          {info.fields?.length && (
            <div className="flex overflow-auto flex-row h-auto border-t border-gray_200 p-large">
              <div className="flex flex-row whitespace-pre gap-base">
                {info.fields.map((item) => {
                  return (
                    <div className="flex flex-col gap-2xs">
                      <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                        {item.name}
                      </Text>
                      {typeof item.value === 'string' || !item.value ? (
                        <Text variant={TypographyVariant.subtitle1}>{item.value}</Text>
                      ) : (
                        item.value
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </DetailedCardDescriptionItemWithoutPadding>
        <DetailedCardDescriptionItem className="flex flex-col justify-between h-auto controls p-large gap-base">
          <div className="flex flex-col w-full gap-small">
            {control.fields?.length && (
              <div className="flex flex-col whitespace-pre gap-base">
                {control.fields.map((item) => {
                  if (!item) return <></>;
                  return (
                    <div className="flex flex-col gap-2xs">
                      <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                        {item.name}
                      </Text>
                      {typeof item.value === 'string' || !item.value ? (
                        <Text variant={TypographyVariant.subtitle1}>{item.value}</Text>
                      ) : (
                        item.value
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {control.actions && <div className="flex flex-col w-full gap-base">{control.actions}</div>}
        </DetailedCardDescriptionItem>
      </DetailedCard>
    </BlockRequestCardRoot>
  );
};
