import { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { Card } from 'refreshed-component/atoms/Card';
import type { CheckBoxProps } from 'refreshed-component/atoms/CheckBox';
import { Empty } from 'refreshed-component/atoms/Empty';
import { Input } from 'refreshed-component/atoms/Input';
import { ConfirmModal } from 'refreshed-component/molecules/ConfirmModal';
import { FilterDropdown, FilterSelections } from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import { toast } from 'refreshed-component/molecules/toast';

import {
  Button,
  ButtonVariant,
  IconName,
  Text,
  TextAlign,
  TextColor,
  TypographyVariant,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import { AssetCategory, CmbStatus, formatter, logger } from '@aircarbon/utils-common';

import type { MyEmbAskBid } from 'pages/account/carbon/CMB/types';
import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { User } from 'state/user';

import { formatDate } from 'utils/helpers';

import { DetailedCardDescriptionItemWithContentList } from '../DetailedCard';

const MyListingAskBidsRoot = styled.div`
  gap: ${({ theme }) => toSpacing(theme)(12)};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const toStatusLabel = (status: CmbStatus) => {
  return (
    {
      [CmbStatus.ACCEPTED]: 'Accepted',
      [CmbStatus.CANCELLED]: 'Canceled',
      [CmbStatus.PENDING]: 'Pending',
      [CmbStatus.CANCELLED_BY_OPS]: 'Canceled',
      [CmbStatus.FORFEIT]: 'Forfeited',
      [CmbStatus.DONE]: 'Filled',
      [CmbStatus.CONFIRMED_BY_SELLER]: 'Accepted',
      [CmbStatus.REJECTED_BY_SELLER]: 'Rejected',
      [CmbStatus.REJECTED]: 'Rejected',
      [CmbStatus.CREDITS_ACQUIRED]: 'Pending Payment',
      [CmbStatus.NEW]: 'Open',
      [CmbStatus.APPROVED]: 'Approved',
      [CmbStatus.CLOSED]: 'Closed',
      [CmbStatus.UNLIST]: 'Unlisted',
      [CmbStatus.PROCESS_TRADE]: 'Processing',
      [CmbStatus.LIST]: 'Listed',
      [CmbStatus.DISABLE]: 'Disabled',
      [CmbStatus.ACCEPTING_BID]: 'Accepting Bid',
    }[status] || status
  );
};

const toStatusColor = (status: CmbStatus) => {
  if ([CmbStatus.ACCEPTED, CmbStatus.CONFIRMED_BY_SELLER, CmbStatus.APPROVED, CmbStatus.LIST].includes(status)) {
    return { text: TextColor.success };
  }

  if (
    [CmbStatus.REJECTED, CmbStatus.REJECTED_BY_SELLER, CmbStatus.CANCELLED, CmbStatus.CANCELLED_BY_OPS].includes(status)
  ) {
    return { text: TextColor.error };
  }

  return {
    text: TextColor.primary,
  };
};

export const MyListingAskBids = ({
  bids,
  onChange,
  tokenUnit,
  tokenAssetCategoryId,
}: {
  bids: MyEmbAskBid[];
  onChange?: () => void;
  tokenUnit: string;
  tokenAssetCategoryId: AssetCategory;
}) => {
  const { askId } = useParams<{ askId: string }>();

  const {
    selector: { getAuthToken },
    status: { canManageCmbBid },
  } = User.useContainer();

  const [txIds, setTxIds] = useState<Record<number, string>>({});
  const [rejectBidId, setRejectBidId] = useState<number | null>(null);
  const [confirmBidId, setConfirmBidId] = useState<number | null>(null);

  const [rejectReason, setRejectReason] = useState('');
  const [rejectReasonError, setRejectReasonError] = useState(false);
  const [rejectReasonType, setRejectReasonType] = useState(0);
  const [rejectReasonTypeError, setRejectReasonTypeError] = useState(false);

  const [confirm, { isLoading: isConfirming }] = useMutation(async ({ bidId }: { bidId: number }) => {
    setConfirmBidId(bidId);
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    return fetch(`/api/user/carbon/cmb-ask/${askId}/confirm/${bidId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    })
      .then((resp) => {
        onChange?.();
        setConfirmBidId(null);
        return resp.json();
      })
      .catch(() => {
        setConfirmBidId(null);
      });
  });

  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});

  const [reject, { isLoading: isRejecting }] = useMutation(async ({ bidId }: { bidId: number }) => {
    setRejectBidId(bidId);
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    return fetch(`/api/user/carbon/cmb-ask/${askId}/reject/${bidId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ rejectReason: rejectReason.trim() }),
    })
      .then((resp) => {
        onChange?.();
        setRejectBidId(null);
        return resp.json();
      })
      .catch(() => {
        setRejectBidId(null);
      });
  });

  const onConfirm = async (bidId: number) => {
    toast.info(`Confirming bid #${bidId}`);
    try {
      const result = await confirm({
        bidId,
      });
      logger.warn(result, 'Confirm result');
      if (result.message) {
        toast.error(result.message);
      } else {
        toast.success('Successfully confirmed.');
        onChange?.();
        setTxIds((prev) => ({
          ...prev,
          [bidId]: result,
        }));
      }
    } catch (error) {
      logger.error(error);
      toast.error('Failed to confirm.');
    }
  };

  const onReject = async (bidId: number) => {
    toast.info(`Rejecting bid #${bidId}`);
    try {
      const result = await reject({
        bidId,
      });
      logger.warn(result, 'Confirm result');
      if (result.message) {
        toast.error(result.message);
      } else {
        toast.success(`Bid ${bidId} was rejected.`);
        setRejectBidId(null);
        onChange?.();
        setTxIds((prev) => ({
          ...prev,
          [bidId]: result,
        }));
      }
    } catch (error) {
      logger.error(error);
      toast.error('Failed to reject.');
    }
  };

  const filters: {
    status: {
      type: 'check-box';
      label: string;
      list: CheckBoxProps[];
    };
  } = {
    status: {
      type: 'check-box',
      label: 'Status',
      list: [
        {
          id: 1,
          label: 'New',
        },
        {
          id: 2,
          label: 'Accepted',
        },
        {
          id: 3,
          label: 'Rejected',
        },
      ],
    },
  };
  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>();
  const filteredBid = bids.filter((item) => {
    const statusSelection = filterSelections?.status?.selection;
    if (!statusSelection?.length) return true;
    if (statusSelection.includes(1) && item.status === 'NEW') {
      return true;
    }
    if (statusSelection.includes(2) && item.status === 'CONFIRMED_BY_SELLER') {
      return true;
    }
    if (statusSelection.includes(3) && item.status === 'REJECTED_BY_SELLER') {
      return true;
    }
    return false;
  });

  if (isLoadingMarketSettings) return <Loading />;

  return (
    <MyListingAskBidsRoot>
      <div className="flex flex-row gap-4 w-full h-full">
        <Card className={'flex flex-col'}>
          <div className="flex flex-row flex-1 justify-between items-center">
            <Text variant={TypographyVariant.h5Title}>Project Bids</Text>
            <div className="flex flex-row gap-4 justify-start">
              <FilterDropdown
                selections={filterSelections}
                onChange={(value) => setFilterSelections(value)}
                list={filters}
              />
            </div>
          </div>
          <FilterSelections
            selections={filterSelections}
            onChange={(value) => setFilterSelections(value)}
            list={filters}
          />
          <div className="flex flex-col gap-4 w-full h-full">
            {filteredBid.map((bid) => {
              const bidStatusColor = toStatusColor(bid.status as CmbStatus);
              return (
                <DetailedCardDescriptionItemWithContentList
                  key={bid.id}
                  controls={
                    <div className="flex flex-col justify-center items-center">
                      {bid.status === 'NEW' && !bid.confirmedUtc && !txIds[bid.id] ? (
                        <div className="flex flex-row h-auto gap-base">
                          {canManageCmbBid() && (
                            <ConfirmModal
                              title={`Reject Bid #${bid.id}`}
                              isOpen={rejectBidId === bid.id && isRejecting}
                              isLoading={rejectBidId === bid.id && isRejecting}
                              accept={{
                                label: 'Reject',
                                icon: IconName.XCircle,
                                callback() {
                                  if (!rejectReason.trim()) {
                                    setRejectReasonError(true);
                                    return false;
                                  } else {
                                    setRejectReasonError(false);
                                    setRejectBidId(bid.id);
                                    onReject(bid.id);
                                  }
                                },
                              }}
                              cancel={{
                                label: 'Cancel',
                                icon: 'no-icon',
                              }}
                              action={
                                <Button
                                  className="flex-1"
                                  variant={ButtonVariant.outlined}
                                  isDisabled={marketSettings?.otcCancelationEnabled === 0}
                                  onPress={() => {
                                    setRejectReasonError(false);
                                    setRejectReason('');
                                  }}
                                  endIcon={IconName.XCircle}
                                >
                                  Reject
                                </Button>
                              }
                            >
                              <div className="flex flex-col items-stretch gap-small">
                                <div className="flex flex-col gap-xs">
                                  <Text variant={TypographyVariant.body2}>Reject Reason</Text>
                                  <Input
                                    placeholder="Reject Reason"
                                    config={{
                                      size: 'base',
                                      color: rejectReasonError ? 'error' : 'gray',
                                    }}
                                    value={rejectReason}
                                    onChange={(event) => {
                                      setRejectReason(event.target.value || '');
                                    }}
                                  />
                                  {rejectReasonError && (
                                    <Text variant={TypographyVariant.body2} color={TextColor.error}>
                                      Reject Reason is required
                                    </Text>
                                  )}
                                </div>
                              </div>
                            </ConfirmModal>
                          )}
                          {canManageCmbBid() && (
                            <ConfirmModal
                              title={`Accept Bid #${bid.id}`}
                              isOpen={confirmBidId === bid.id && isConfirming}
                              isLoading={confirmBidId === bid.id && isConfirming}
                              accept={{
                                label: 'Confirm',
                                callback() {
                                  setConfirmBidId(bid.id);
                                  onConfirm(bid.id);
                                },
                              }}
                              action={
                                <Button
                                  className="flex-1"
                                  variant={ButtonVariant.secondary}
                                  isDisabled={marketSettings?.otcMatchingEnabled === 0}
                                  endIcon={IconName.CheckCircle}
                                >
                                  Accept
                                </Button>
                              }
                            >
                              <div className="flex flex-col items-center gap-base">
                                <Text
                                  align={TextAlign.center}
                                  color={TextColor.secondary}
                                  variant={TypographyVariant.subtitle1}
                                >
                                  Once you accept the bid, you're confirming the availability of the{' '}
                                  {tokenAssetCategoryId === AssetCategory.rec ? 'certificates' : 'credits'} and to
                                  proceed with the trade.
                                </Text>
                                <Text variant={TypographyVariant.subtitle1}>Do you want to proceed?</Text>
                              </div>
                            </ConfirmModal>
                          )}
                        </div>
                      ) : (
                        <div className="flex flex-row h-auto gap-base">
                          {!!bid.status && (
                            <Text color={bidStatusColor.text} variant={TypographyVariant.body2}>
                              {toStatusLabel(bid.status as CmbStatus)}
                            </Text>
                          )}
                        </div>
                      )}
                    </div>
                  }
                >
                  <div className="flex flex-col flex-1">
                    <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                      Bid
                    </Text>
                    <Text color={TextColor.secondary}>#{bid.id}</Text>
                  </div>
                  <div className="flex flex-col flex-1">
                    <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                      Price (Per {tokenUnit})
                    </Text>
                    <Text color={TextColor.secondary}>${formatter.formatNumber(bid.price, 2)}</Text>
                  </div>
                  <div className="flex flex-col flex-1">
                    <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                      Qty ({tokenUnit})
                    </Text>
                    <Text color={TextColor.secondary}>
                      {formatter.formatNumber(bid.quantity, 0)} {tokenUnit}
                    </Text>
                  </div>
                  <div className="flex flex-col flex-1">
                    <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                      Date
                    </Text>
                    <Text color={TextColor.secondary}>{formatDate(bid.createdUtc)}</Text>
                  </div>
                </DetailedCardDescriptionItemWithContentList>
              );
            })}
            {filteredBid.length === 0 && (
              <Empty title="No bids placed" description="There are no bids placed yet on this listing" />
            )}
          </div>
        </Card>
      </div>
    </MyListingAskBidsRoot>
  );
};
