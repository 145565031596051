import {
  Badge,
  BorderColor,
  Button,
  ButtonVariant,
  IconName,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toBorderColor,
  toLayerBackground,
} from '@aircarbon/ui';

export const Batch: React.FC<{
  label: string;
  assets: Array<{
    label: string;
    value: string;
  }>;
  quantity: string;
  onRetire?(): void;
}> = (props) => {
  const { label, assets, quantity, onRetire } = props;
  return (
    <StyledBatch>
      <Assets>
        <Asset>
          <Badge value={label} />
        </Asset>
        {assets.map((asset, key) => {
          return (
            <Asset key={key}>
              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                {asset.label}
              </Text>
              <Text variant={TypographyVariant.h6Title}>{asset.value}</Text>
            </Asset>
          );
        })}
      </Assets>
      <Quantity>
        <Text color={TextColor.secondary}>Quantity (tCO2e)</Text>
        <Text variant={TypographyVariant.h4Title}>{quantity}</Text>
      </Quantity>
      {!!onRetire && (
        <Actions>
          <Button variant={ButtonVariant.secondary} endIcon={IconName.Retire} onPress={onRetire}>
            Retire
          </Button>
        </Actions>
      )}
    </StyledBatch>
  );
};

const StyledBatch = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  background: ${({ theme }) => toLayerBackground(theme)('field')};

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

const Assets = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 24px;
  gap: 16px;

  @media (max-width: 1367px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1023px) {
    display: flex;
  }

  @media (max-width: 719px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Asset = styled.div`
  flex-grow: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 33.333%;
`;

const Quantity = styled.div`
  border-inline-start: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  border-inline-end: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  padding: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1023px) {
    border-inline-start: none;
    border-inline-end: none;
    border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    border-bottom: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  }
`;

const Actions = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
`;
