import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import { Dropdown, type DropdownItem } from 'refreshed-component/atoms/Dropdown';
import { toast } from 'refreshed-component/molecules/toast';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { Table } from 'refreshed-component/templates/Table';

import { Button, IconName, Pagination, Text, TextColor, TypographyVariant } from '@aircarbon/ui';
import {
  AssetCategory,
  type AssetCategoryCode,
  TokenRetirementStatus,
  formatter,
  helpers,
} from '@aircarbon/utils-common';

import { UI } from 'state/ui';
import { User } from 'state/user';

import useTokenTypes from 'hooks/useTokenTypes';

import { fetchUserTokenHistory } from 'data-provider/user/fetchUserTokenHistory';

export enum BatchHistoryType {
  Deliver = 'deliver',
  Retire = 'retire',
  BasketRetirement = 'basket_retirement',
}

const itemsPerPage = 100;

export const BatchHistory = ({ type }: { type: BatchHistoryType }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [statusDropdownItem, setDropdownItemForStatus] = useState<DropdownItem | null>(null);
  const {
    status: { canAccessRecTokenBurn },
    selector: { getAuthToken },
  } = User.useContainer();
  const { getSetting } = UI.useContainer();
  const isRecsEnabled = canAccessRecTokenBurn();
  const retirementCertificateTemplate = getSetting('global_retirement_certificate_template');

  const assetCategories = useMemo(() => {
    if (isRecsEnabled) {
      return [AssetCategory[AssetCategory.token], AssetCategory[AssetCategory.rec]];
    }

    return [AssetCategory[AssetCategory.token]];
  }, [isRecsEnabled]);

  const { tokenTypesByScId } = useTokenTypes({
    assetCategories: assetCategories as Array<AssetCategoryCode>,
  });
  const [updateRetireId, setUpdateRetireId] = useState<number | null>();

  const statusQuery = statusDropdownItem && statusDropdownItem.id !== 'All' ? `&status=${statusDropdownItem.id}` : '';
  const fetchHistory = async (type: BatchHistoryType) => {
    const response = await fetchUserTokenHistory({
      page: currentPage,
      limit: itemsPerPage,
      statusQuery,
      type: type === BatchHistoryType.BasketRetirement ? 'retire' : type,
      isBasket: type === BatchHistoryType.BasketRetirement ? 'YES' : undefined,
    });

    return response as {
      items: any[];
      total: number;
      totalPage: number;
    };
  };

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const { status, resolvedData } = usePaginatedQuery([type, statusQuery, currentPage, itemsPerPage], fetchHistory);
  const pagesCount = resolvedData?.totalPage ?? 1;

  let columns: { [key: string]: { label: string; minWidth?: number } } = {
    id: {
      label: '#',
      minWidth: 100,
    },
    asset: {
      label: 'Asset',
    },
    project: {
      label: 'Project',
    },
    quantity: {
      label: 'Quantity',
    },
    date: {
      label: 'Submitted On',
    },
    status: {
      label: 'Status',
    },
  };

  let sticky = {
    left: ['id'],
    right: ['status'],
  };

  if (type === 'retire' && retirementCertificateTemplate) {
    columns = { ...columns, retirementCertificate: { label: 'Retirement Certificate' } };
    sticky = { ...sticky, right: ['retirementCertificate'] };
  }

  const downloadRetirementCertificate = async (id: number) => {
    toast.info('Downloading retirement certificate is in progress...');
    try {
      const authToken = await getAuthToken();
      await helpers.downloadFileFromUrl(
        `/api/user/token-retirement/${id}/certificate`,
        {
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
        `Retirement-Certificate-${id}.pdf`,
      );

      toast.success('Retirement certificate is downloaded successfully');
    } catch (error: any) {
      toast.error(error?.message || 'Error downloading the retirement certificate');
    }
  };

  return (
    <PageHolder>
      <PageSections type="card">
        <div className={'flex flex-col flex-1 items-start justify-start gap-large'}>
          <div className="flex flex-row gap-base">
            <Dropdown
              config={{
                color: 'gray',
              }}
              list={[
                { label: 'All', id: 'All' },
                { label: 'Done', id: 'DONE' },
                { label: 'Pending', id: 'PENDING' },
                { label: 'Draft', id: 'DRAFT' },
                { label: 'Cancelled', id: 'CANCELLED' },
                { label: 'Processing', id: 'PROCESSING' },
              ]}
              onSelectItem={(item) => {
                setCurrentPage(1);
                setDropdownItemForStatus(item);
              }}
            >
              <div className="flex flex-row gap-2">
                <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                  Status:
                </Text>
                <Text variant={TypographyVariant.body2}> {statusDropdownItem?.label || 'All'}</Text>
              </div>
            </Dropdown>
          </div>
          <Table
            config={{
              sticky,
              columns,
              rows: resolvedData?.items?.map(
                (
                  item: {
                    id: number;
                    tokenTypeId: string;
                    quantity: number;
                    status: string;
                    createdUtc: string;
                    projectName: string;
                    retirementPageUuid: string;
                    __tokenRetireNfts__: Record<string, any>;
                  },
                  index: number,
                ) => {
                  const date = format(new Date(item.createdUtc), 'MMM d yyyy h:mm:ss a');
                  return {
                    id: <>{item.id.toString()}</>,
                    asset: <>{tokenTypesByScId()?.[Number(item?.tokenTypeId)]?.name ?? item?.tokenTypeId}</>,
                    quantity: <>{formatter.formatNumber(item.quantity, 0)}</>,
                    status: (
                      <div key={item.id} className="flex flex-row justify-center justify-items-center">
                        <div className="pr-2">{item.status}</div>
                        {item.status?.toLowerCase() === 'draft' && type === 'retire' && updateRetireId !== item.id ? (
                          <button type="button" onClick={() => setUpdateRetireId(item.id)}>
                            <svg
                              className="w-5 h-5"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </button>
                        ) : (
                          ''
                        )}
                        {item.status?.toLowerCase() === 'draft' && type === 'retire' && updateRetireId === item.id ? (
                          <button type="button" onClick={() => setUpdateRetireId(null)}>
                            <svg
                              className="w-5 h-5"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                              />
                            </svg>
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    ),
                    project: item.projectName || '',
                    date: <>{date}</>,
                    _key: item.id.toString(),
                    retirementCertificate: type === 'retire' &&
                      retirementCertificateTemplate &&
                      item.status === TokenRetirementStatus.DONE && (
                        <Button endIcon={IconName.Download} onPress={() => downloadRetirementCertificate(item?.id)}>
                          Download
                        </Button>
                      ),
                  };
                },
              ),
              loading: status !== 'success',
            }}
          />
          <Pagination onChange={onChangePage} currentPage={currentPage} pagesCount={pagesCount} />
        </div>
      </PageSections>
    </PageHolder>
  );
};
