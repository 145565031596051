import { useState } from 'react';
import { useQuery } from 'react-query';
import { NavLink, Redirect, Route, useHistory } from 'react-router-dom';

import { Text, TypographyVariant, useTheme } from '@aircarbon/ui';

import Simplebar from 'pages/account/trading/components/Simplebar';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useTokenTypes from 'hooks/useTokenTypes';

import { fetchAvailableBalance } from 'data-provider/user/fetchAvailableBalance';

import RFQAllResponses from '../../pages/account/RFQ/AllResponses';
import BalanceSummary from '../../pages/account/RFQ/BalanceSummary';
import RequestForQuoteForm from '../../pages/account/RFQ/Form';
import RFQList from '../../pages/account/RFQ/List';
import {
  BalanceSummaryWrapper,
  BoxTitle,
  Main,
  MainWrapper,
  MenuWrapper,
  PricesWrapper,
  Sidebar,
  Wrapper,
} from '../../pages/account/RFQ/Styled';
import TokenPrices from '../../pages/account/RFQ/TokenPrices';
import { useEntityTokenPrice, usePairs } from '../../pages/account/trading/hooks';

export const RFQ = () => {
  const {
    selector: { getAccountAddress, getUserId },
    status: { canTradeRFQ },
  } = User.useContainer();
  const { getSetting, screenSize } = UI.useContainer();
  const {
    selector: { mainCcyScId },
  } = Entity.useContainer();
  const [selectedRFQ, setSelectedRFQ] = useState<number | null>(null);
  const history = useHistory();
  const { isLoadingEntityTokenPrice, entityTokenPrice } = useEntityTokenPrice({
    type: 'BUY',
  });
  const { activeTokenTypesNonGER } = useTokenTypes({});

  const { pairs: pairsWatchList } = usePairs({
    assetCategories: ['token'],
    includeMarketData: true,
  });

  const { data: accountBalance } = useQuery(['account-balance'], async () => {
    try {
      const response = await fetchAvailableBalance({ getRootBalance: 'no', ccyTypeId: mainCcyScId });
      return response;
    } catch (error) {
      console.log(error);
    }
  });

  const onFormSubmit = () => {
    history.push('/account/carbon-finder/my-bids');
  };

  const balanceAndPricing = (
    <>
      <PricesWrapper screenSize={screenSize}>
        <BoxTitle>Allowed Bid Pricing Range</BoxTitle>
        <TokenPrices
          isLoadingEntityTokenPrice={isLoadingEntityTokenPrice}
          entityTokenPrice={entityTokenPrice}
          activeTokens={activeTokenTypesNonGER()}
          pairsWatchList={pairsWatchList}
        />
      </PricesWrapper>
      <BalanceSummaryWrapper>
        <BoxTitle>My Balance</BoxTitle>
        <BalanceSummary
          tokenTypes={activeTokenTypesNonGER()}
          tokens={accountBalance?.tokens ?? []}
          balance={accountBalance?.balance ?? 0}
          available={accountBalance?.availableAmount ?? 0}
          openOrders={accountBalance?.openOrders ?? []}
          pendingTransactionAmount={accountBalance?.pendingTransactionAmount ?? 0}
          pendingTransactionTokens={accountBalance?.pendingTransactionTokens ?? []}
        />
      </BalanceSummaryWrapper>
    </>
  );

  return (
    <Wrapper screenSize={screenSize}>
      <Sidebar screenSize={screenSize}>
        <Simplebar
          content={{
            minHeight: '100%',
            position: 'absolute',
          }}
        >
          <div className="top">
            <Header />
            <MenuWrapper screenSize={screenSize}>
              {(screenSize === 'small' || screenSize === 'medium') && (
                <NavLink to="/account/carbon-finder/balance-and-pricing">Balance & Pricing</NavLink>
              )}
              {canTradeRFQ() && <NavLink to="/account/carbon-finder/place-bid">Place Bid +</NavLink>}
              <NavLink to="/account/carbon-finder/my-bids">My Bids</NavLink>
              <NavLink to="/account/carbon-finder/bids-to-me">Bids I Can Fill</NavLink>
            </MenuWrapper>
            {!(screenSize === 'small' || screenSize === 'medium') ? (
              balanceAndPricing
            ) : (
              <Route path="/account/carbon-finder/balance-and-pricing">{balanceAndPricing}</Route>
            )}
          </div>
        </Simplebar>
      </Sidebar>
      <MainWrapper>
        <Main>
          <Route path="/account/carbon-finder">
            {screenSize === 'small' || screenSize === 'medium' ? (
              <Redirect to="/account/carbon-finder/balance-and-pricing" />
            ) : (
              <Redirect to="/account/carbon-finder/my-bids" />
            )}
          </Route>
          {canTradeRFQ() && (
            <Route exact path="/account/carbon-finder/place-bid">
              <div className="mb-large">
                <RequestForQuoteForm
                  ccyTypeId={mainCcyScId}
                  minQty={Number(getSetting('global_carbonfinder_bidMinLots') || 1)}
                  onSubmit={onFormSubmit}
                  onClose={() => {
                    history.push(
                      screenSize === 'small' || screenSize === 'medium'
                        ? '/account/carbon-finder/balance-and-pricing'
                        : '/account/carbon-finder/my-bids',
                    );
                  }}
                  pairsWatchList={pairsWatchList}
                  activeTokens={activeTokenTypesNonGER()}
                />
              </div>
            </Route>
          )}
          <Route exact path={['/account/carbon-finder/my-bids', '/account/carbon-finder/bids-to-me']}>
            <Route exact path="/account/carbon-finder/my-bids">
              <div className={`mainContent ${selectedRFQ ? 'rfqSelected' : ''}`}>
                <div className="absolute w-full h-full">
                  <Simplebar>
                    <RFQList
                      addr={getAccountAddress()}
                      userId={getUserId()}
                      filter="my-bids"
                      onSelect={setSelectedRFQ}
                      pairsWatchList={pairsWatchList}
                    />
                    <div className="flex flex-col pb-large pl-large pr-large gap-large">
                      <Text>Trade Confirmations</Text>
                      <RFQAllResponses userId={getUserId()} />
                    </div>
                  </Simplebar>
                </div>
              </div>
            </Route>
            <Route exact path="/account/carbon-finder/bids-to-me">
              <div className={`mainContent ${selectedRFQ ? 'rfqSelected' : ''}`}>
                <div className="absolute w-full h-full">
                  {/* <Simplebar>
                    <RFQList
                      addr={getAccountAddress()}
                      userId={getUserId()}
                      filter="bids-to-me"
                      onSelect={setSelectedRFQ}
                      pairsWatchList={pairsWatchList}
                    />
                    <div className="flex flex-col pb-large pl-large pr-large gap-large">
                      <Text>Trade Confirmations</Text>
                      <RFQAllResponses userId={getUserId()} />
                    </div>
                  </Simplebar> */}
                </div>
              </div>
            </Route>
          </Route>
        </Main>
      </MainWrapper>
    </Wrapper>
  );
};

const Header = () => {
  // const theme = useTheme();

  // console.log(theme);
  return (
    <div className="flex flex-col w-full mb-base gap-xs">
      <Text variant={TypographyVariant.h6Title}>CarbonFinder</Text>
      <Text variant={TypographyVariant.body2}>Bid by project criteria</Text>
    </div>
  );
};
