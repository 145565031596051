import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Input } from 'refreshed-component/atoms/Input';
import { toast } from 'refreshed-component/molecules/toast';
import * as yup from 'yup';

import { Button, ButtonType, IconName, Text, TextAlign, TextAs, TextColor, TypographyVariant } from '@aircarbon/ui';

import FormDevTool from 'components/FormDevTool';

import { UI } from 'state/ui';
import { User } from 'state/user';

const schema = () =>
  yup.object().shape({
    bankName: yup
      .string()
      .trim()
      .required('Please enter bank name')
      .max(100, 'Maximum 100 characters allowed to enter. please remove extra characters.'),
    bankAddress: yup
      .string()
      .trim()
      .required('Please enter bank name')
      .max(100, 'Maximum 100 characters allowed to enter. please remove extra characters.'),
    bankCountry: yup
      .string()
      .trim()
      .required('Please enter bank country')
      .max(100, 'Maximum 100 characters allowed to enter. please remove extra characters.'),
    swiftCode: yup
      .string()
      .trim()
      .required('Please enter swift code')
      .max(100, 'Maximum 100 characters allowed to enter. please remove extra characters.'),
    accountIBAN: yup
      .string()
      .trim()
      .required('Please enter account IBAN')
      .max(100, 'Maximum 100 characters allowed to enter. please remove extra characters.'),

    contactPhone: yup.string().trim().required('Please enter contact phone'),
    accountName: yup
      .string()
      .trim()
      .required('Please enter account name')
      .max(100, 'Maximum 100 characters allowed to enter. please remove extra characters.'),
    referenceNote: yup
      .string()
      .trim()
      .max(256, 'Maximum 256 characters allowed to enter. please remove extra characters.'),
  });

type FormData = {
  bankName: string;
  bankAddress: string;
  bankCountry: string;
  swiftCode: string;
  accountIBAN: string;
  contactPhone: string;
  accountName: string;
  referenceNote?: string;
};

const BankAccount = () => {
  const {
    bankAccount,
    fetchFullProfile,
    setBankAccount,
    selector: { getAuthToken, getBankVerificationStatus, isBankAccountApproved, isBankAccountRejected },
  } = User.useContainer();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema()),
    defaultValues: bankAccount,
  });

  useEffect(() => {
    if (bankAccount) {
      reset(bankAccount);
    }
  }, [bankAccount, reset]);

  const [saving, setSaving] = useState(false);
  const { getSetting } = UI.useContainer();

  const operationsEmail = getSetting('web_settings_supportEmailAddress');

  const [mutate] = useMutation(async (formData: Record<string, any>) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();

    const response = await fetch('/api/user/user/bank-account', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error('Failed to update bank account');
    }

    return response.json();
  });

  const onSubmitHandler = async (formData: any) => {
    try {
      setSaving(true);
      const bankInfo = { ...bankAccount, ...formData };
      const result = await mutate({ ...bankInfo });

      if (result.status === 'success') {
        toast.success(`Your bank details have been submitted for verification. We'll notify you once approved.`);
        setBankAccount(bankInfo);
        fetchFullProfile();
      } else {
        toast.error(result.message);
      }
      setSaving(false);
    } catch (error) {
      setSaving(false);
      toast.error((error as Error).message);
    }
  };

  const isFormDisabled = isBankAccountApproved();

  return (
    <div>
      <FormDevTool control={control} />

      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="flex flex-col gap-base">
          <Text className="mb-large" align={TextAlign.center} variant={TypographyVariant.subtitle1}>
            Current Bank Details
          </Text>
          {!!bankAccount.accountIBAN && getBankVerificationStatus() === 'SUBMITTED' && (
            <Text align={TextAlign.center} color={TextColor.secondary} variant={TypographyVariant.body2}>
              Your bank account is being verified. We will notify you via email once the details have been approved.
            </Text>
          )}
          {isBankAccountRejected() && (
            <Text align={TextAlign.center} color={TextColor.secondary} variant={TypographyVariant.body2}>
              We have found discrepancies in the submitted bank information. Please make the changes in the form below.
              If you have any questions, please contact us at{' '}
              <Text as={TextAs.span} variant={TypographyVariant.subtitle1}>
                <a href={`mailto:${operationsEmail}`}>{operationsEmail}</a>
              </Text>
            </Text>
          )}
          {isBankAccountApproved() && (
            <Text align={TextAlign.center} color={TextColor.secondary} variant={TypographyVariant.body2}>
              Your bank account is approved. If you wish to change to a new bank account please contact us at{' '}
              <Text as={TextAs.span} variant={TypographyVariant.subtitle1}>
                <a href={`mailto:${operationsEmail}`}>{operationsEmail}</a>
              </Text>
            </Text>
          )}
          <Text align={TextAlign.center} color={TextColor.secondary} variant={TypographyVariant.body2}>
            Please ensure the bank account information corresponds to the bank statement submitted during KYC.
          </Text>

          <div className="flex flex-col flex-1 gap-xs">
            <Text variant={TypographyVariant.body2}>Bank Name *</Text>
            <Input
              id="bankName"
              config={{
                color: 'gray',
                size: 'base',
              }}
              value={getValues('bankName')}
              onChange={(event) => {
                setValue('bankName', (event?.target.value as any) || undefined, {
                  shouldValidate: true,
                });
              }}
              required={true}
              disabled={isFormDisabled}
            />
            {errors.bankName?.message && (
              <Text variant={TypographyVariant.body2} color={TextColor.error}>
                {errors.bankName.message}
              </Text>
            )}
          </div>
          <div className="flex flex-col flex-1 gap-xs">
            <Text variant={TypographyVariant.body2}>Bank Address *</Text>
            <Input
              id="bankAddress"
              config={{
                color: 'gray',
                size: 'base',
              }}
              value={getValues('bankAddress')}
              onChange={(event) => {
                setValue('bankAddress', (event?.target.value as any) || undefined, {
                  shouldValidate: true,
                });
              }}
              required={true}
              disabled={isFormDisabled}
            />
            {errors.bankAddress?.message && (
              <Text variant={TypographyVariant.body2} color={TextColor.error}>
                {errors.bankAddress.message}
              </Text>
            )}
          </div>

          <div className="flex flex-col w-full sm:flex-row gap-base">
            <div className="flex flex-col flex-1 gap-xs">
              <Text variant={TypographyVariant.body2}>Bank Country *</Text>
              <Input
                id="bankCountry"
                config={{
                  color: 'gray',
                  size: 'base',
                }}
                value={getValues('bankCountry')}
                onChange={(event) => {
                  setValue('bankCountry', (event?.target.value as any) || undefined, {
                    shouldValidate: true,
                  });
                }}
                required={true}
                disabled={isFormDisabled}
              />
              {errors.bankCountry?.message && (
                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                  {errors.bankCountry.message}
                </Text>
              )}
            </div>
            <div className="flex flex-col flex-1 gap-xs">
              <Text variant={TypographyVariant.body2}>Swift Code *</Text>
              <Input
                id="swiftCode"
                config={{
                  color: 'gray',
                  size: 'base',
                }}
                value={getValues('swiftCode')}
                onChange={(event) => {
                  setValue('swiftCode', (event?.target.value as any) || undefined, {
                    shouldValidate: true,
                  });
                }}
                required={true}
                disabled={isFormDisabled}
              />
              {errors.swiftCode?.message && (
                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                  {errors.swiftCode.message}
                </Text>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full sm:flex-row gap-base">
            <div className="flex flex-col flex-1 gap-xs">
              <Text variant={TypographyVariant.body2}>Bank Account Number *</Text>
              <Input
                id="accountIBAN"
                config={{
                  color: 'gray',
                  size: 'base',
                }}
                value={getValues('accountIBAN')}
                onChange={(event) => {
                  setValue('accountIBAN', (event?.target.value as any) || undefined, {
                    shouldValidate: true,
                  });
                }}
                required={true}
                disabled={isFormDisabled}
              />
              {errors.accountIBAN?.message && (
                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                  {errors.accountIBAN.message}
                </Text>
              )}
            </div>
            <div className="flex flex-col flex-1 gap-xs">
              <Text variant={TypographyVariant.body2}>Reference / Note</Text>
              <Input
                id="referenceNote"
                config={{
                  color: 'gray',
                  size: 'base',
                }}
                value={getValues('referenceNote')}
                onChange={(event) => {
                  setValue('referenceNote', (event?.target.value as any) || undefined, {
                    shouldValidate: true,
                  });
                }}
                disabled={isFormDisabled}
              />
              {errors.referenceNote?.message && (
                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                  {errors.referenceNote.message}
                </Text>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full sm:flex-row gap-base">
            <div className="flex flex-col flex-1 gap-xs">
              <Text variant={TypographyVariant.body2}>Beneficiary Name *</Text>
              <Input
                id="accountName"
                config={{
                  color: 'gray',
                  size: 'base',
                }}
                value={getValues('accountName')}
                onChange={(event) => {
                  setValue('accountName', (event?.target.value as any) || undefined, {
                    shouldValidate: true,
                  });
                }}
                required={true}
                disabled={isFormDisabled}
              />
              {errors.accountName?.message && (
                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                  {errors.accountName.message}
                </Text>
              )}
            </div>
            <div className="flex flex-col flex-1 gap-xs">
              <Text variant={TypographyVariant.body2}>Contact Phone *</Text>
              <Input
                id="contactPhone"
                config={{
                  color: 'gray',
                  size: 'base',
                }}
                value={getValues('contactPhone')}
                onChange={(event) => {
                  setValue('contactPhone', (event?.target.value as any) || undefined, {
                    shouldValidate: true,
                  });
                }}
                required={true}
                disabled={isFormDisabled}
              />
              {errors.contactPhone?.message && (
                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                  {errors.contactPhone.message}
                </Text>
              )}
            </div>
          </div>
          {!isFormDisabled && (
            <div className="flex justify-center items-center mt-6">
              <Button isLoading={saving} type={ButtonType.Submit} endIcon={IconName.ArrowEnd}>
                Save Bank Account
              </Button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default BankAccount;
