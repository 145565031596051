import type * as React from 'react';
import { useForm } from 'react-hook-form';
import { Input } from 'refreshed-component/atoms/Input';

import { Button, ButtonVariant, Text, TextColor, TypographyVariant } from '@aircarbon/ui';

interface Props {
  email: string;
  disable?: boolean;
  onSubmit?: (code: string) => void;
  onCancel?: () => void;
}
const EmailOtp: React.FC<Props> = ({ email, disable, onSubmit, onCancel }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{
    code: string;
  }>();

  const handleForm = (values: { code: string }) => {
    if (onSubmit) {
      onSubmit(values?.code);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <form onSubmit={handleSubmit(handleForm)}>
      <div className="flex flex-col flex-1 items-stretch w-full gap-base">
        <div className="flex flex-col w-full gap-base">
          <Text variant={TypographyVariant.h3Title}>Email OTP Verification</Text>
          <Text className="flex-1">We have sent you an Email to {email}, enter the code below</Text>
        </div>
        <div className="flex flex-col flex-1 items-stretch w-full gap-base">
          <div className="flex flex-col flex-1 gap-xs">
            <Input
              config={{
                color: 'gray',
                size: 'base',
              }}
              formRegister={register('code', {
                required: 'Required',
              })}
              maxLength={6}
              minLength={6}
              required
              placeholder="Authenticator code"
            />
            {errors.code?.message && (
              <Text variant={TypographyVariant.body2} color={TextColor.error}>
                {errors.code.message}
              </Text>
            )}
          </div>
        </div>
        <div className="flex flex-row flex-1 w-full gap-medium">
          <Button variant={ButtonVariant.outlined} className="flex-1" onPress={() => handleCancel()}>
            Cancel
          </Button>
          <Button className="flex-1" isLoading={disable}>
            Verify
          </Button>
        </div>
      </div>
    </form>
  );
};

export default EmailOtp;
