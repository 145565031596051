import { type Dispatch, type SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { HitArea } from 'refreshed-component/atoms/HitArea';
import { Colors, Radius, Spacing } from 'refreshed-component/design-system';

import { Icon, IconName, Text, TextColor, styled, toSpacing } from '@aircarbon/ui';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;

export const Item = styled.div<{ isSelected?: boolean; isDisabled?: boolean; opacity?: number }>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => toSpacing(theme)(4)} ${({ theme }) => toSpacing(theme)(6)};
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected
      ? `   
            background-color: var(${Colors.gray_900});
            color: var(${Colors.gray_0});
            &:hover {
                color: var(${Colors.gray_0});
                background-color: var(${Colors.gray_500});
            }`
      : `   
            color: var(${Colors.gray_500});
            &:hover {
                color: var(${Colors.gray_0});
                background-color: var(${Colors.gray_500}); 
            }`}
  ${({ isDisabled }) => (isDisabled ? `pointer-events: none;` : '')}
  ${({ opacity }) => (opacity ? `opacity: ${opacity};` : '')}
`;

export const List = styled.div`
  display: flex;
  align-items: center;
  border-radius: var(${Radius.medium});
  border: 1px solid var(${Colors.gray_300});
  overflow: hidden;
  > ${Item} {
    border-right: 1px solid var(${Colors.gray_300});
    &:last-child {
      border-right: 0px;
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: var(${Spacing.xs});
`;

type usePaginationActions = {
  page: number;
  pageSize: number;
  setPage: Dispatch<SetStateAction<number>>;
  setPageSize: Dispatch<SetStateAction<number>>;
};

export const usePagination = () => {
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);
  return {
    page,
    pageSize,
    setPage,
    setPageSize,
  };
};

export const Pagination = (props: {
  actions?: usePaginationActions;
  total: number;
  page?: number;
  pageSize?: number;
  onChange?: (page: number, pageSize: number) => void;
}) => {
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);

  const { onChange } = props;
  const onClick = useCallback(
    (newPage: number, newPageSize: number = pageSize) => {
      setPage(newPage);
      setPageSize(newPageSize);
      onChange?.(newPage, newPageSize);
      props.actions?.setPageSize(newPageSize);
      props.actions?.setPage(newPage);
    },

    [onChange, pageSize],
  );

  const newPage = props.page || props.actions?.page;
  const newPageSize = props.pageSize || props.actions?.pageSize;

  useEffect(() => {
    if (page !== newPage && newPage) setPage(newPage);
    if (pageSize !== newPageSize && newPageSize) setPageSize(newPageSize);
  }, [page, pageSize, newPage, newPageSize]);

  const { side, haveLeft, haveRight, leftList, rightList, totalPage, prevEnabled, nextEnabled } = useMemo(() => {
    const side = 2;
    const totalPage = Math.ceil((props.total || 0) / pageSize);
    const haveLeft = page - (side + 1) > 0 ? true : false;
    const haveRight = totalPage - page >= side + 1 ? true : false;
    const leftList = [...Array(side).keys()]
      .map((item, index) => {
        const itemPage = page - 1 - index;
        return (
          itemPage >= 1 && (
            <Item key={`${itemPage}-${index}`} onClick={() => onClick(itemPage)}>
              {itemPage}
            </Item>
          )
        );
      })
      .reverse();
    const rightList = [...Array(side).keys()].map((item, index) => {
      const itemPage = page + 1 + index;
      return (
        itemPage <= totalPage && (
          <Item key={`${itemPage}-${index}`} onClick={() => onClick(itemPage)}>
            {itemPage}
          </Item>
        )
      );
    });
    const prevEnabled = page - 1 >= 1;
    const nextEnabled = page + 1 <= totalPage;
    return {
      side,
      haveLeft,
      haveRight,
      leftList,
      rightList,
      totalPage,
      prevEnabled,
      nextEnabled,
    };
  }, [onClick, page, pageSize, props.total]);

  if (!props.total) {
    return <></>;
  }

  return (
    <Container>
      <Controls>
        <Text color={TextColor.secondary}>Showing</Text>
        <Dropdown
          list={[
            {
              id: 5,
              label: '5',
            },
            {
              id: 10,
              label: '10',
            },
            {
              id: 20,
              label: '20',
            },
            {
              id: 50,
              label: '50',
            },
            {
              id: 100,
              label: '100',
            },
          ]}
          config={{
            color: 'gray',
            size: 'xs',
          }}
          onSelectItem={(item) => {
            onClick(1, Number(item.id));
          }}
          placeholder="Select"
        >
          {pageSize}
        </Dropdown>
        <Text color={TextColor.secondary}>of</Text> <Text>{props.total}</Text>
      </Controls>
      <List>
        <Item
          isDisabled={!prevEnabled}
          opacity={prevEnabled ? 1 : 0.3}
          onClick={() => {
            setPage(page - 1);
          }}
        >
          <HitArea width={20} height={20}>
            <Icon name={IconName.ChevronStart} size="0.625rem" />
          </HitArea>
        </Item>
        {haveLeft && (
          <>
            <Item onClick={() => onClick(1)}>1</Item>
            {page - 1 > side + 1 && <Item isDisabled={true}>...</Item>}
          </>
        )}
        {leftList}
        <Item isSelected={true}>{page}</Item>
        {rightList}
        {haveRight && (
          <>
            {totalPage - page > side + 1 && <Item isDisabled={true}>...</Item>}
            <Item onClick={() => onClick(totalPage)}>{totalPage}</Item>
          </>
        )}
        <Item
          isDisabled={!nextEnabled}
          opacity={nextEnabled ? 1 : 0.3}
          onClick={() => {
            setPage(page + 1);
          }}
        >
          <HitArea width={20} height={20}>
            <Icon name={IconName.ChevronEnd} size="0.625rem" />
          </HitArea>
        </Item>
      </List>
    </Container>
  );
};
