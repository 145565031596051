import { yupResolver } from '@hookform/resolvers/yup';
import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { DatePicker } from 'refreshed-component/atoms/DatePicker';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { Spacing } from 'refreshed-component/design-system';
import Modal, { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import { RadioBoxList } from 'refreshed-component/molecules/RadioBoxList';
import { toast } from 'refreshed-component/molecules/toast';
import * as yup from 'yup';

import {
  Button,
  ButtonType,
  ButtonVariant,
  Card,
  Checkbox,
  IconName,
  InputText,
  InputTextSize,
  Layer,
  Select,
  Text,
  TextAs,
  TextColor,
  TypographyVariant,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import {
  AssetCategory,
  type AssetCategoryCode,
  FeeType,
  formatter,
  helpers,
  logger,
  projects,
} from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import FormDevTool from 'components/FormDevTool';
import Loading from 'components/styled/Loading';

import { Account } from 'state/account';
import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useDebounce from 'hooks/useDebounce';
import useFee from 'hooks/useFee';
import useTokenTypes from 'hooks/useTokenTypes';

import { fetchUserRegistryAccount } from 'data-provider/user/fetchUserRegistryAccount';

import { convertTextNumberToValue } from 'utils/helpers';

import RegistryAccountForm from './RegistryAccountForm';

export const Filters = styled.div`
  position: relative;
  z-index: 9;
  display: flex;
`;

const SCOPES: Array<number> = [1, 2, 3];

const retireSchema = (availableAmount: number, currencyBalance: number, feeAmount: number, mainCcyCode: string) =>
  yup.object().shape({
    quantity: yup
      .number()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        return convertTextNumberToValue(originalValue);
      })
      .min(1)
      .positive()
      .integer()
      .test(
        'hasEnoughAmount',
        `Max ${formatter.formatNumber(availableAmount)}`,
        function validateAmount(val: any): boolean {
          return Number(val) <= Number(availableAmount);
        },
      )
      .test(
        'hasEnoughMoney',
        `Insufficient balance - Available ${mainCcyCode}${currencyBalance}`,
        function validateAmount(val: any): boolean {
          return feeAmount <= Number(currencyBalance);
        },
      )
      .required(),
    offsettingStartDate: yup.date().nullable(),
    offsettingEndDate: yup.string().when('offsettingStartDate', (date: string) => {
      if (date) {
        return yup.date().required('Please select End Date. it is required');
      }
      return yup.date().nullable();
    }),
    retirementBeneficiary: yup.string().max(256).required('Retirement beneficiary is required'),
    retirementReasonComments: yup.string().max(256).optional(),
  });

const deliverySchema = (availableAmount: number, currencyBalance: number, feeAmount: number, mainCcyCode: string) =>
  yup.object().shape({
    quantity: yup
      .number()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        return convertTextNumberToValue(originalValue);
      })
      .min(1)
      .positive()
      .integer()
      .test(
        'hasEnoughAmount',
        `Max ${formatter.formatNumber(availableAmount)}`,
        function validateAmount(val: any): boolean {
          return Number(val) <= Number(availableAmount);
        },
      )
      .test(
        'hasEnoughMoney',
        `Insufficient balance - Available ${mainCcyCode}${currencyBalance}`,
        function validateAmount(val: any): boolean {
          return feeAmount <= Number(currencyBalance);
        },
      )
      .required(),
  });

export type FormData = {
  quantity: number;
  retirementReason?: string;
  retirementReasonComments?: string;
  makeRetirementPublic: string;
  certificateLanguage?: string;
  retirementBeneficiary?: string;
  offsettingStartDate?: Date | null;
  offsettingEndDate?: Date | null;
};

interface Props {
  type: 'PHYSICAL_DELIVERY' | 'RETIREMENT';
  totalAmount: number;
  openAmount: number;
  currentAmount: number;
  currencyBalance: number;
  stIds: Array<number>;
  scTokenTypeId: number;
  tokenAssetCategory: AssetCategory;
  batchId: number;
  project: Record<string, any>;
  onSuccess?: () => void;
}

const RetireDeliverBatchForm: React.FC<Props> = (props) => {
  const {
    type,
    scTokenTypeId,
    tokenAssetCategory,
    totalAmount,
    openAmount,
    currentAmount,
    currencyBalance,
    stIds,
    project,
    batchId,
    onSuccess,
  } = props;
  const { onTxRequestSuccess } = Account.useContainer();
  const {
    selector: { mainCcyCode, mainCcyNumDecimals },
  } = Entity.useContainer();
  const {
    selector: { getAuthToken },
    status: { canAccessRecTokenBurn },
  } = User.useContainer();
  const [saving, setSaving] = useState(false);
  const [selectedRegistry, setSelectedRegistry] = useState<{ name: string; details: string; id: number } | null>(null);
  const queryCache = useQueryCache();
  const [scopes, setScopes] = useState<any>({});
  const isRecsEnabled = canAccessRecTokenBurn();
  const assetCategories = useMemo(() => {
    if (isRecsEnabled) {
      return [AssetCategory[AssetCategory.token], AssetCategory[AssetCategory.rec]];
    }

    return [AssetCategory[AssetCategory.token]];
  }, [isRecsEnabled]);
  const { tokenTypes } = useTokenTypes({
    assetCategories: assetCategories as Array<AssetCategoryCode>,
  });
  const { screenSize } = UI.useContainer();
  const [tokenQty, setTokenQty] = useState(0);
  const debouncedTokenQty = useDebounce(tokenQty, 500);

  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});

  const availableAmount = totalAmount - openAmount;

  const { feeAmount, isLoading: isLoadingFeeAmount } = useFee({
    params: {
      feeType: type === 'RETIREMENT' ? FeeType.ASSET_RETIREMENT_FEE : FeeType.ASSET_DELIVERY_FEE,
      assetCategoryId: tokenAssetCategory,
      tokenQty: debouncedTokenQty,
    },
    options: { enabled: debouncedTokenQty > 0 },
  });

  const defaultValues: FormData = {
    quantity: 0,
    retirementReason: '',
    retirementReasonComments: '',
    makeRetirementPublic: 'yes',
    certificateLanguage: '',
    retirementBeneficiary: '',
    offsettingStartDate: null,
    offsettingEndDate: null,
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<FormData>({
    defaultValues: { ...defaultValues },
    resolver: yupResolver(
      type === 'RETIREMENT'
        ? retireSchema(Math.min(availableAmount, currentAmount), currencyBalance, feeAmount, mainCcyCode)
        : deliverySchema(Math.min(availableAmount, currentAmount), currencyBalance, feeAmount, mainCcyCode),
    ),
  });

  watch();

  const { data: registryAccounts } = useQuery(
    `/api/user/user/registry-account?name=${project.TXT_REGISTRY}`,
    async () => {
      const accounts = await fetchUserRegistryAccount(project.TXT_REGISTRY);

      if (accounts?.[1] > 0) {
        setSelectedRegistry(accounts[0][accounts[0].length - 1]);
      }

      return accounts;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const [mutate] = useMutation(
    async (formData: Record<string, any>) => {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      return fetch(type === 'RETIREMENT' ? '/api/user/token/retire' : '/api/user/token/deliver', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        } as any,
        body: JSON.stringify(formData),
      });
    },
    {
      onSuccess: async (data) => {
        if (data.ok) {
          reset({ ...defaultValues });
          setScopes({});
          onTxRequestSuccess('We are processing your request. It may take several seconds.');
          onSuccess?.();
        } else {
          const error = await data.json();
          toast.error(error?.message ?? 'Something went wrong!');
          logger.error(error);
        }
        setSaving(false);
      },
      onError: (error: Error) => {
        setSaving(false);
        toast.error(error?.message ?? 'Something went wrong!');
        logger.error(error);
      },
    },
  );

  const onSubmit = useCallback(
    ({
      quantity,
      retirementReason,
      retirementReasonComments,
      makeRetirementPublic,
      retirementBeneficiary,
      offsettingEndDate,
      offsettingStartDate,
    }: FormData) => {
      setSaving(true);

      const commonParameters = {
        tokenTypeId: scTokenTypeId,
        stIds,
        batchId,
        quantity,
        projectMetadata: {
          id: project.TXT_PROJECT_ID,
          name: project.TXT_PROJECT_NAME,
          registry: project.TXT_REGISTRY,
          vintage: project.vintage,
        },
      };

      if (type === 'PHYSICAL_DELIVERY') {
        toast.default('Requesting carbon delivery');
        mutate({
          ...commonParameters,
          registryAccountId: selectedRegistry?.id ?? 0,
        });
      }

      if (type === 'RETIREMENT') {
        toast.default('Requesting carbon retirement');
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        mutate({
          ...commonParameters,
          retirementReason,
          retirementReasonComments,
          makeRetirementPublic,
          retirementBeneficiary,
          scopes,
          offsettingStartDate,
          offsettingEndDate,
          userTimezone,
          certificateLanguage: 'English',
        });
      }
    },
    [
      scTokenTypeId,
      stIds,
      batchId,
      project.TXT_PROJECT_ID,
      project.TXT_PROJECT_NAME,
      project.TXT_REGISTRY,
      project.vintage,
      type,
      mutate,
      selectedRegistry?.id,
      scopes,
    ],
  );

  const onRegistryAdded = () => {
    queryCache.invalidateQueries(`/api/user/user/registry-account?name=${project.TXT_REGISTRY}`);
  };

  const tokenType = tokenTypes.find((type) => type.scId === scTokenTypeId);
  const tokenUnit = tokenType?.uom?.name;

  const retirementReasons =
    projects.registryRetirementReasons.find(
      (registry: { name: string; retirementReasons: Array<string> }) => registry.name === project.TXT_REGISTRY,
    )?.retirementReasons ?? [];

  if (isLoadingMarketSettings) return <Loading />;

  const AddRegistry = (
    <Modal
      title={'Add Registry Account'}
      action={
        <Button variant={ButtonVariant.outlined} className="flex-1" startIcon={IconName.PlusCircle}>
          Add Registry Account
        </Button>
      }
    >
      {({ onClose }) => (
        <RegistryAccountForm
          registryName={project.TXT_REGISTRY}
          onSubmit={() => {
            onRegistryAdded();
            onClose();
          }}
          onCancel={() => onClose()}
        />
      )}
    </Modal>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalContent>
        <Layer>
          <div className="flex flex-wrap gap-large">
            <Layer>
              <Card>
                <div className="flex flex-col w-full p-small gap-small">
                  <div className="flex flex-row gap-small">
                    <div className="flex flex-col flex-1 gap-small">
                      <div className="flex flex-col">
                        <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
                          Project Name
                        </Text>
                        <Text className="max-w-full">{project.TXT_PROJECT_NAME}</Text>
                      </div>
                      <div className="flex flex-col">
                        <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
                          Batch ID
                        </Text>
                        <Text>{batchId}</Text>
                      </div>
                      <div className="flex flex-col">
                        <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
                          Asset Type
                        </Text>
                        <Text>
                          {tokenType?.symbol} - {tokenType?.fullName}
                        </Text>
                      </div>
                    </div>
                    <div className="flex flex-col flex-1 gap-small">
                      <div className="flex flex-col">
                        <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
                          Project ID
                        </Text>
                        <Text>{project.TXT_PROJECT_ID}</Text>
                      </div>
                      <div className="flex flex-col">
                        <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
                          Registry
                        </Text>
                        <Text>{project.TXT_REGISTRY}</Text>
                      </div>
                      <div className="flex flex-col">
                        <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
                          Balance ({tokenType?.symbol})
                        </Text>
                        <Text>
                          {formatter.formatNumber(Math.min(availableAmount, currentAmount), 0)} {tokenUnit}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Layer>

            {type === 'PHYSICAL_DELIVERY' && (
              <div className="flex flex-col flex-1 gap-xs">
                <Text variant={TypographyVariant.subtitle2}>Deliver to</Text>
                {registryAccounts?.[0].length ? (
                  <Dropdown
                    key={'selectedRegistry'}
                    content={{
                      before: (onClose) => (
                        <div onKeyUp={onClose} onClick={onClose} className="flex flex-row flex-1 px-medium pt-medium">
                          {AddRegistry}
                        </div>
                      ),
                    }}
                    list={
                      registryAccounts?.[0].map(({ id, name, details }, index) => {
                        const info = helpers.parseRegistryInfo(details ?? '');
                        return {
                          id,
                          label: (
                            <div key={id} className="flex flex-col my-xs">
                              <Text variant={TypographyVariant.subtitle1}>Account Name: {info.accountName || '-'}</Text>
                              <Text variant={TypographyVariant.subtitle2} color={TextColor.secondary}>
                                Account ID: {info.accountId || '-'}
                              </Text>
                            </div>
                          ),
                        };
                      }) || []
                    }
                    selected={[selectedRegistry?.id || '']}
                    onSelectItem={(item) => {
                      registryAccounts &&
                        setSelectedRegistry(registryAccounts[0].find((account) => account.id === item.id) || null);
                    }}
                    config={{
                      color: 'gray',
                      size: 'sm',
                    }}
                    placeholder="Select Registry"
                  >
                    {(list) => {
                      return list?.map((item) => item.label);
                    }}
                  </Dropdown>
                ) : (
                  AddRegistry
                )}
              </div>
            )}
            <FormDevTool control={control} />
            <div className="flex flex-col w-full gap-large">
              {type === 'RETIREMENT' && (
                <>
                  <div className={'flex flex-col gap-large'}>
                    <div className="flex flex-col gap-xs">
                      {retirementReasons.length > 0 ? (
                        <Select
                          label={'Retirement Reason'}
                          size={InputTextSize.s}
                          value={getValues('retirementReason') || ''}
                          key={'retirementReason'}
                          placeholder="Select Retirement Reason"
                          items={retirementReasons.map((value) => {
                            return {
                              value: value,
                              title: value,
                            };
                          })}
                          onChange={({ value }) => {
                            setValue('retirementReason', value);
                          }}
                        />
                      ) : (
                        <InputText
                          size={InputTextSize.s}
                          label="Retirement Reason"
                          value={getValues('retirementReason')}
                          error={errors.retirementReason?.message}
                          onChange={(e) => {
                            setValue('retirementReason', e.target.value);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className={'flex-1 flex flex-col md:flex-row gap-large'}>
                    <div className="flex flex-col flex-1 gap-xs">
                      <InputText
                        label={'Retirement beneficiary*'}
                        value={getValues('retirementBeneficiary')}
                        size={InputTextSize.s}
                        suffix={`${getValues('retirementBeneficiary')?.length || 0}/256`}
                        onChange={(event) => {
                          const value = event.target.value || '';
                          if (value.length <= 256) setValue('retirementBeneficiary', value);
                        }}
                        error={errors.retirementBeneficiary?.message}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-large">
                    <div className="flex flex-col flex-1">
                      <Text className="mb-xs" variant={TypographyVariant.subtitle2}>
                        Start Date
                      </Text>
                      <DatePicker
                        selected={getValues('offsettingStartDate')}
                        maxDate={getValues('offsettingEndDate')}
                        showTimeSelect
                        onChange={(date) => {
                          // Convert from user timezone to UTC
                          setValue('offsettingStartDate', date ?? undefined);
                        }}
                        onClear={() => {
                          setValue('offsettingStartDate', undefined);
                        }}
                        placeholderText="Select Start Date"
                      />
                      {errors.offsettingStartDate?.message && (
                        <Text variant={TypographyVariant.body2} color={TextColor.error}>
                          {errors.offsettingStartDate.message}
                        </Text>
                      )}
                    </div>
                    <div className="flex flex-col flex-1">
                      <Text className="mb-xs" variant={TypographyVariant.subtitle2}>
                        End Date
                      </Text>
                      <DatePicker
                        minDate={getValues('offsettingStartDate')}
                        selected={getValues('offsettingEndDate')}
                        showTimeSelect
                        onChange={(date) => {
                          setValue('offsettingEndDate', date ?? undefined);
                        }}
                        onClear={() => {
                          setValue('offsettingEndDate', undefined);
                        }}
                        placeholderText="Select End Date"
                      />
                      {errors.offsettingEndDate?.message && (
                        <Text variant={TypographyVariant.body2} color={TextColor.error}>
                          {errors.offsettingEndDate.message}
                        </Text>
                      )}
                    </div>
                  </div>
                  <div className={'flex flex-col md:flex-row gap-large'}>
                    <div className={'flex-1 flex flex-col gap-xs'}>
                      <Text variant={TypographyVariant.subtitle2}>Offset Scope</Text>
                      <Checkboxes>
                        {SCOPES.map((scope) => (
                          <Checkbox
                            isChecked={scopes[scope] !== undefined}
                            key={scope}
                            label={`Scope ${scope}`}
                            onChange={() => {
                              const newScopes: any = { ...scopes };
                              newScopes[scope] = newScopes[scope] ? undefined : true;
                              setScopes(newScopes);
                            }}
                          />
                        ))}
                      </Checkboxes>
                    </div>
                    <div className="flex flex-col flex-1 gap-xs">
                      <Text variant={TypographyVariant.subtitle2}>Make Retirement Public ?</Text>
                      <RadioBoxList
                        list={[
                          {
                            id: 'yes',
                            label: 'Yes',
                          },
                          {
                            id: 'no',
                            label: 'No',
                          },
                        ]}
                        onClick={(item) => {
                          setValue('makeRetirementPublic', item.id.toString());
                        }}
                        selected={getValues('makeRetirementPublic') || ''}
                      />
                    </div>
                  </div>
                  <div className={`flex ${screenSize === 'small' ? 'flex-col' : 'flex-row'}`}>
                    <div className="w-full">
                      <div className={'flex-1 flex flex-col gap-xs'}>
                        <InputText
                          label="Other notes"
                          size={InputTextSize.s}
                          value={getValues('retirementReasonComments')}
                          error={errors.retirementReasonComments?.message}
                          suffix={`${getValues('retirementReasonComments')?.length || 0}/256`}
                          onChange={(event) => {
                            const value = event.target.value || '';
                            if (value.length <= 256) setValue('retirementReasonComments', value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {((registryAccounts && registryAccounts?.[1] > 0) || type === 'RETIREMENT') && (
                <div className="flex flex-col gap-xs">
                  <InputText
                    label={'Quantity*'}
                    size={InputTextSize.s}
                    error={errors.quantity?.message}
                    suffix={tokenUnit}
                    value={getValues('quantity') ? getValues('quantity').toString() : undefined}
                    onChange={(event) => {
                      const value = event.target.value;
                      setTokenQty(Number(value ?? 0));
                      setValue('quantity', value as unknown as number, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    Transaction Fee:{' '}
                    <Text variant={TypographyVariant.body2} as={TextAs.span}>
                      {mainCcyCode}
                      {formatter.formatNumber(feeAmount, mainCcyNumDecimals)}
                    </Text>
                  </Text>
                </div>
              )}
            </div>
            {saving && <div className="absolute top-0 left-0 z-10 w-full h-full bg-black opacity-10" />}
            <Layer>
              <Card className="w-full">
                <div className="flex flex-col p-small gap-3xs">
                  <Text variant={TypographyVariant.subtitle1}>
                    Available Quantity: {formatter.formatNumber(Math.min(availableAmount, currentAmount), 0)}{' '}
                    {tokenUnit}
                  </Text>
                  <Text color={TextColor.secondary}>
                    {tokenType?.symbol} Total Qty: {formatter.formatNumber(totalAmount, 0)} {tokenUnit}
                  </Text>
                  <Text color={TextColor.secondary}>
                    {tokenType?.symbol} Open Qty: {formatter.formatNumber(openAmount, 0)} {tokenUnit}
                  </Text>
                </div>
              </Card>
            </Layer>
          </div>
        </Layer>
      </ModalContent>
      {((registryAccounts && registryAccounts?.[1] > 0) || type === 'RETIREMENT') && (
        <ModalFooter>
          <Button
            className="flex-1"
            isLoading={isLoadingFeeAmount}
            type={ButtonType.Submit}
            isDisabled={(type === 'PHYSICAL_DELIVERY' && !selectedRegistry) || marketSettings?.carbonEntryEnabled === 0}
            variant={ButtonVariant.secondary}
            endIcon={IconName.ArrowEnd}
          >
            Confirm
          </Button>
        </ModalFooter>
      )}
    </form>
  );
};

export default RetireDeliverBatchForm;

const Checkboxes = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  flex-direction: row;
`;
