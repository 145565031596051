import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';

import { styled } from '@aircarbon/ui';

import type { ScreenSizes } from 'state/ui';

import type { ThemeColors, ThemeMode } from '../trading/components/Themes';

const gap = '7px';

export const CarbonFinderBrand = styled.div`
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 1.5rem;
  letter-spacing: -0.5px;
`;

export const MenuWrapper = styled.div<{ screenSize?: ScreenSizes }>`
  border-radius: var(${Radius.medium});
  padding: var(${Spacing.base});
  display: flex;
  gap: var(${Spacing.small});
  margin-bottom: 10px;
  text-align: right;
  background-color: var(${Colors.gray_50});

  label {
    text-transform: uppercase;
    font-size: 0.9em;
    color: var(${Colors.gray_800});
    margin-top: -5px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  a {
    font-size: var(${FontSize.large});
    &.active {
      color: var(${Colors.gray_900});
      font-weight: var(${FontWeight.bold});
    }
  }

  ${(props) => {
    if (props.screenSize === 'small') {
      return `
        flex-direction: column;
        flex-wrap: wrap;
        text-align: center;
      `;
    } else if (props.screenSize === 'medium') {
      return `
        flex-direction: row;
        flex-wrap: wrap;
        > a {
          white-space: pre;
        }
      `;
    } else {
      return `
        flex-direction: column;
      `;
    }
  }}
`;

export const Wrapper = styled.div<{ screenSize?: ScreenSizes }>`
  background: var(${Colors.gray_100});
  color: var(${Colors.gray_900});
  display: flex;
  overflow: hidden;
  padding: ${gap};
  gap: ${gap};
  font-size: 13.5px;

  a.active {
    filter: brightness(1);
  }

  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return `height: auto; min-height: 100%; flex-direction: column;`;
    } else {
      return `height: 100%;`;
    }
  }}
`;

export const PageTitleWrapper = styled.div`
  font-size: 13px;
`;

export const MainWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  border-radius: var(${Radius.medium});
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: 100%;
  flex: 1;
  gap: ${gap};
  position: relative;
  background: var(${Colors.gray_0});

  .mainContent {
    flex: 1;
    overflow: hidden;
    position: relative;
  }
`;

export const ResponsesWrapper = styled.div`
  max-height: 170px;
  overflow: auto;
`;

export const Sidebar = styled.div<{ screenSize?: ScreenSizes }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: ${gap};
  overflow-y: auto;
  background: var(${Colors.gray_0});
  border-radius: var(${Radius.medium});
  overflow: hidden;

  .top {
    background: var(${Colors.gray_0});
    padding: var(${Spacing.base});
    min-height: 100%;
  }

  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return `width: 100%;`;
    } else {
      return `
        max-width: 330px;
        min-width: 330px;
      `;
    }
  }}
`;

export const BalanceSummaryWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

export const PricesWrapper = styled.div<{ screenSize?: ScreenSizes }>`
  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return `height: auto;`;
    } else {
      return `
        max-height: 300px;
      `;
    }
  }}
  overflow: auto;
  margin-bottom: 10px;
`;

export const Bid = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  background: var(${Colors.gray_900});
  color: var(${Colors.gray_200});
  transition: all 0.2s;

  &.selected {
    height: 100%;
  }
`;

export const BidTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 200;
  letter-spacing: 1px;
  margin-bottom: 2rem;
`;

export const BidInfoWrapper = styled.div`
  display: flex;
  gap: 2.5rem;
  align-items: start;
  margin: 10px 0;

  &.large {
    gap: 4.5rem;
  }

  &.normal {
    gap: 4rem;
  }

  .divider {
    align-self: stretch;
    width: 1px;
    background: var(${Colors.gray_400});
  }
`;

export const BidItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  font-size: var(${FontSize.base});
  font-weight: var(${FontWeight.bold});
  color: var(${Colors.gray_900});
  width: 130px;
  gap: var(${Spacing.xs});

  &.w-auto {
    width: auto;
  }

  label {
    text-transform: uppercase;
    margin-right: var(${Spacing.base});
    font-size: var(${FontSize.small});
    font-weight: var(${FontWeight.medium});
    color: var(${Colors.gray_500});
  }

  &.large {
    width: auto;
    font-size: 1.2rem;
    font-weight: 300;

    label {
      font-size: 0.9rem;
      margin-bottom: 5px;
    }
  }

  &.normal {
    width: auto;
    font-size: 1rem;
    font-weight: 300;

    label {
      font-size: 0.9rem;
      margin-bottom: 5px;
    }
  }
`;

export const BidTable = styled.table<{ screenSizes?: ScreenSizes }>`
  ${(props) => {
    if (props.screenSizes === 'small' || props.screenSizes === 'medium') {
      return `width: auto;`;
    } else {
      return `width: 100%;`;
    }
  }}

  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;

  th,
  td {
    border-top: 1px solid var(${Colors.gray_200}) !important;
    background: var(${Colors.gray_50});
    padding: 6px 1rem;
    text-align: center;

    &.assetName {
      width: 70px;
    }

    &.largeNumber {
      width: 160px;
    }
  }

  th {
    font-weight: 500;
    position: sticky;
    top: 0;
    z-index: 9;
  }

  th:not(:last-child) {
    border: 0;
  }

  td {
    font-weight: 300;
    border-top: 1px solid var(${Colors.gray_200});
  }

  tr {
    &.FILLING,
    &.PENDING {
      td {
        font-weight: bold;
        background: rgba(${(props) => props.theme.brandRbgColor}, 0.4);
      }
    }
  }

  input {
    background: var(${Colors.gray_100});
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid var(${Colors.gray_200});

    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.brandColor};
      outline: none;
    }
  }

  tfoot {
    font-weight: 700;

    td {
      border-top: 2px solid rgba(${(props) => props.theme.brandRbgColor}, 0.5);
    }
  }

  a {
    color: ${(props) => props.theme.brandColor};
  }
`;
export const BidButton = styled.button<{ themeColor: ThemeColors }>`
  display: inline-flex;
  align-items: center;
  background: ${({ themeColor }) => themeColor?.rfq.sidebar.table.button.background};
  color: ${({ themeColor }) => themeColor?.rfq.sidebar.table.button.color};
  border-bottom: 3px solid rgba(${(props) => props.theme.brandRbgColor}, 0.7);
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  justify-content: center;

  &:disabled {
    color: ${({ themeColor }) => themeColor?.rfq.sidebar.table.button.disabled.color};
    &:hover {
      background: ${({ themeColor }) => themeColor?.rfq.sidebar.table.button.disabled.hoverBackground};
    }
  }

  svg {
    margin-left: auto;
  }

  &:hover {
    background: ${(props) => props.theme.brandColor};
  }

  &.warning {
    border-bottom: 3px solid rgba(255, 0, 0, 0.5);

    &:hover {
      background: rgba(255, 0, 0, 0.3);
    }
  }
`;
export const Unit = styled.span`
  font-size: 0.75em;
  font-weight: 300;
  margin-right: 7px;
`;
export const CriteriaItem = styled.div<{ themeColor: ThemeColors; themeMode: ThemeMode }>`
  display: flex;
  border-radius: 8px;
  font-size: 1em;
  background: ${({ themeColor }) => themeColor.rfq.sidebar.table.criteria.background};
  padding: 5px 10px;
  gap: 5px;
  align-items: center;
  font-weight: 300;
  height: 30px;

  .criteriaLabel {
    color: rgba(${(props) => props.theme.brandRbgColor}, 1);
    filter: brightness(1.3);
    filter: ${({ themeMode }) => (themeMode ? 'brightness(0.8)' : 'brightness(1.3)')};
  }

  .criteriaValues {
    display: flex;
    gap: 5px;
  }

  .criteriaValue,
  .criteriaLabel {
    white-space: nowrap;
  }
`;
export const BoxTitle = styled.div`
  background: var(${Colors.gray_100});
  padding: var(${Spacing.small}) var(${Spacing.base});
  font-size: var(${FontSize.base});
  font-weight: var(${FontWeight.semibold});
  letter-spacing: 1px;
  border-top-left-radius: var(${Spacing.xs});
  border-top-right-radius: var(${Spacing.xs});
`;
