import { Text, TextColor, TypographyVariant, styled, toLayerBackground } from '@aircarbon/ui';

export const BalanceCard: React.FC<{
  label: string;
  isTransparent?: boolean;
  logo?: string;
}> = (props) => {
  const { label, isTransparent = false, logo, children } = props;
  return (
    <StyledBalanceCard $isTransparent={isTransparent}>
      {!!logo && (
        <Logo>
          <img src={logo} />
        </Logo>
      )}
      <Content>
        <Text color={TextColor.secondary}>{label}</Text>
        <Text variant={TypographyVariant.h6Title}>{children}</Text>
      </Content>
    </StyledBalanceCard>
  );
};

const StyledBalanceCard = styled.div<{
  $isTransparent: boolean;
}>`
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid
    ${({ $isTransparent, theme }) => ($isTransparent ? 'transparent' : `${toLayerBackground(theme)('fieldAccent')}`)};
  padding: 32px;
  border-radius: 8px;
  background-color: ${({ $isTransparent, theme }) =>
    $isTransparent ? 'transparent' : `${toLayerBackground(theme)('layer')}`};
`;

const Logo = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div``;
