import { useState } from 'react';
import { queryCache, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Countdown from 'refreshed-component/atoms/CountDown';
import { Empty } from 'refreshed-component/atoms/Empty';
import AddAskProjectForm from 'refreshed-component/forms/AddAskProjectForm';
import { FilterDropdown, type FilterRadioBox, FilterSelections } from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import Modal from 'refreshed-component/molecules/Modal';
import { Pagination, usePagination } from 'refreshed-component/molecules/Pagination';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { MarketPlaceProjectCard } from 'refreshed-component/templates/market-board/MarketPlaceProjectCard';

import {
  Badge,
  Button,
  ButtonVariant,
  IconName,
  InputText,
  Layer,
  Text,
  TextAs,
  TextColor,
  TypographyVariant,
} from '@aircarbon/ui';
import { type AssetCategory, formatter } from '@aircarbon/utils-common';

import type { MyEmbAsk } from 'pages/account/carbon/CMB/types';
import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { UI } from 'state/ui';
import { User } from 'state/user';

import useCurrencies from 'hooks/useCurrencies';
import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { fetchMyCMBAsks } from 'data-provider/market-board/fetchMyCMBAsks';

import { formatDate } from 'utils/helpers';
import { toAssetMinMaxLots } from 'utils/toAssetMinMaxLots';

export const askStatusLabel = (ask: MyEmbAsk) => {
  if (ask?.status === 'NEW' || ask.__carbonProject__?.statusCode === 'NEW') return 'PENDING APPROVAL';
  if (ask?.status === 'REJECTED' || ask.__carbonProject__?.statusCode === 'REJECTED') return 'REJECTED';
  switch (ask.status) {
    case 'LIST':
      return 'LISTED';

    case 'UNLIST':
      return 'UNLISTED';
  }
  return ask.status;
};

export const MyListingAsks = () => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [isNewAuctionModalVisible, setIsNewAuctionModalVisible] = useState(false);
  const {
    status: { canBidAuction },
  } = User.useContainer();
  const { product } = useMarketplaceProduct();

  const { getSetting } = UI.useContainer();
  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();

  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});
  const { askMinLots, askMaxLots, bidMinLots } = toAssetMinMaxLots({
    assetCategory: Number(product),
    getSetting,
  });
  const pagination = usePagination();
  const tokenUnit = getSetting('web_settings_tokenUnit');

  const filters: {
    status: FilterRadioBox;
  } = {
    status: {
      type: 'radio-box',
      label: 'Status',
      list: [
        {
          id: 'listed',
          label: 'Listed Projects',
        },
        {
          id: 'requests',
          label: 'Projects With Bids',
        },
        {
          id: 'unlisted',
          label: 'Unlisted',
        },
        {
          id: 'new',
          label: 'Pending Approval',
        },
        {
          id: 'rejected',
          label: 'Rejected Projects',
        },
      ],
    },
  };

  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>({});
  const askUrl = `/api/user/carbon/my-cmb-ask?page=${pagination.page}&limit=${
    pagination.pageSize
  }&isAuction=yes&assetCategoryId=${product}&status=${filterSelections?.status?.selection || 'all'}${
    search ? `&searchBy=${search}` : ''
  }`;

  const { data, isLoading, refetch } = useQuery(askUrl, async () =>
    fetchMyCMBAsks({
      page: pagination.page,
      limit: pagination.pageSize,
      isAuction: 'yes',
      status: filterSelections?.status?.selection || 'all',
      search,
      assetCategoryId: product,
    }),
  );

  const asks = data?.items;

  const onPressListNewAuction = () => {
    setIsNewAuctionModalVisible(true);
  };

  const onCloseNewAuctionModal = () => {
    setIsNewAuctionModalVisible(false);
  };

  return (
    <>
      <PageHolder>
        <PageSections>
          <PageControls
            title="My Listings"
            controls={{
              secondary: (
                <>
                  {canBidAuction() && (
                    <div className="flex flex-row justify-start">
                      <Button
                        isDisabled={marketSettings?.otcEntryEnabled === 0}
                        startIcon={IconName.PlusCircle}
                        onPress={onPressListNewAuction}
                      >
                        List New Auction
                      </Button>

                      <Modal
                        title={'List a New Auction'}
                        isOpen={!!isNewAuctionModalVisible}
                        onClose={onCloseNewAuctionModal}
                      >
                        {({ onClose, onLoading }) => {
                          return (
                            <AddAskProjectForm
                              minLotQty={askMinLots}
                              maxLotQty={askMaxLots}
                              bidMinLots={bidMinLots}
                              isProjectOffer={false}
                              onSuccess={onClose}
                              onLoading={(isLoading) => onLoading(isLoading)}
                              refetchAsks={refetch}
                              assetCategoryId={Number(product) as AssetCategory}
                            />
                          );
                        }}
                      </Modal>
                    </div>
                  )}
                </>
              ),
            }}
          />
        </PageSections>
        <PageSections type="card" className="flex flex-col">
          <PageControls
            controls={{
              primary: (
                <>
                  <Layer>
                    <InputText
                      placeholder="Search..."
                      icon={IconName.Search}
                      value={search}
                      onChange={(e) => setSearch(e.target.value || '')}
                    />
                  </Layer>

                  <FilterDropdown
                    selections={filterSelections}
                    onChange={(value) => setFilterSelections(value)}
                    list={filters}
                    appearance={{
                      size: 'base',
                    }}
                  />
                </>
              ),
              secondary: <Pagination actions={pagination} total={data?.total ?? 0} />,
            }}
          />
          <FilterSelections
            selections={filterSelections}
            onChange={(value) => setFilterSelections(value)}
            list={filters}
          />
          <div className="flex flex-col w-full gap-large">
            {asks &&
              (Array.isArray(asks) ? asks : [])
                .filter((ask) => {
                  return search
                    ? ask?.__carbonProject__?.name?.toLocaleLowerCase()?.startsWith?.(search.trim().toLocaleLowerCase())
                    : true;
                })
                .map((ask) => {
                  const newBids = ask?.__carbonEmbBids__?.filter((bid: { status: string }) => bid.status === 'NEW');
                  const confirmedBids = ask?.__carbonEmbBids__?.filter((bid: { status: string }) =>
                    ['CONFIRMED_BY_SELLER', 'DONE'].includes(bid.status),
                  );
                  const tokenAsset = (ask.__tokenAsset__ as any | undefined) || {};

                  const ccyAsset = currenciesObjById?.[ask?.quoteAssetId];
                  const project = { ...ask?.__carbonProject__, vintageYear: ask?.carbonProjectVintageYear };
                  return (
                    <MarketPlaceProjectCard
                      key={ask.id}
                      info={{
                        onClick: () => {
                          history.push(`/account/auctions/my-listings/${ask.id}`);
                        },
                        tokenAsset,
                        carbonProject: project,
                        id: `Project #${ask.id}`,
                        carbonProjectVintageYear: ask?.carbonProjectVintageYear,
                        indicators: [
                          `New Bids: ${newBids?.length || 0}`,
                          `Accepted Bids: ${confirmedBids?.length || 0}`,
                        ],
                      }}
                      controls={
                        <>
                          <div className="flex flex-row justify-between whitespace-pre gap-base">
                            <Badge value={askStatusLabel(ask)} />
                          </div>
                          <div className="flex flex-row justify-between whitespace-pre gap-base">
                            <div className="flex flex-col gap-xs">
                              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                Price ({ccyAsset?.symbol})
                              </Text>
                              <Text variant={TypographyVariant.h5Title}>
                                {ccyAsset?.code}
                                {formatter.formatNumber(ask.price, ccyAsset?.numDecimals)}
                              </Text>
                            </div>
                            <div className="flex flex-col gap-xs">
                              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                Listed Qty ({ask?.__tokenAsset__?.__uom__?.code || tokenUnit})
                              </Text>
                              <Text variant={TypographyVariant.h5Title}>
                                {formatter.formatNumber(ask?.quantity - ask?.openBidsTotalQty, 0)}{' '}
                              </Text>
                            </div>
                          </div>
                          <div className="flex overflow-hidden flex-col justify-between whitespace-pre rounded-medium">
                            <div className="flex flex-col bg-gray_100 p-base">
                              <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                                Started on:{' '}
                                <Text as={TextAs.span} variant={TypographyVariant.subtitle2}>
                                  {formatDate(ask.startDate)}
                                </Text>
                              </Text>
                              <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                                Ends on:{' '}
                                <Text as={TextAs.span} variant={TypographyVariant.subtitle2}>
                                  {formatDate(ask.endDate)}
                                </Text>
                              </Text>
                            </div>
                            {ask.status === 'LIST' && (
                              <div className="flex flex-col text-gray_900 bg-gray_200 p-base">
                                <Countdown
                                  date={new Date(ask.endDate)}
                                  endLabel={'This auction has ended.'}
                                  onZero={() => queryCache.invalidateQueries(askUrl)}
                                />
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col">
                            <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                              Account: {ask.__user__?.firstName} {ask.__user__?.lastName} [{ask.userId}]
                            </Text>
                            <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                              Placed by: {ask.__createdByUser__?.firstName} {ask.__createdByUser__?.lastName} [
                              {ask.createdBy}]
                            </Text>
                          </div>
                          {canBidAuction() && (
                            <div className="flex flex-row gap-base">
                              <Button
                                variant={ButtonVariant.secondary}
                                className="flex-1"
                                endIcon={IconName.Bids}
                                onPress={() => {
                                  history.push(`/account/auctions/my-listings/${ask.id}`);
                                }}
                              >
                                Bids
                              </Button>
                            </div>
                          )}
                        </>
                      }
                    />
                  );
                })}
            {(isLoading || isLoadingMarketSettings) && (
              <div className="p-large">
                <Loading isOverLay={false} />
              </div>
            )}
            {!isLoading && (Array.isArray(asks) ? asks : []).length === 0 && (
              <Empty
                title="No Listings"
                description="You have no listings yet. Your action listings will show up here."
              />
            )}
            {/* If total items outside of view show bottom pagination */}
            {(data?.total ?? 0) > 4 && (
              <PageControls
                controls={{
                  secondary: <Pagination actions={pagination} total={data?.total ?? 0} />,
                }}
              />
            )}
          </div>
        </PageSections>
      </PageHolder>
    </>
  );
};
