import {
  BorderColor,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toBorderColor,
  toLayerBackground,
} from '@aircarbon/ui';

export const Balance: React.FC<{
  title: string;
  value: string;
}> = ({ title, value }) => {
  return (
    <StyledBalance>
      <Logo>
        <img src={'/logos/GER.png'} />
      </Logo>
      <Content>
        <Text color={TextColor.secondary}>{title}</Text>
        <Text variant={TypographyVariant.h6Title}>{value}</Text>
      </Content>
    </StyledBalance>
  );
};

const StyledBalance = styled.div`
  display: flex;
  border-radius: 8px;
  gap: 8px;
  background-color: ${({ theme }) => toLayerBackground(theme)('fieldAccent')};
  padding: 24px;
`;

const Logo = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => toLayerBackground(theme)('layer')};
  border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  border-radius: 50%;

  & > img {
    width: 80%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
