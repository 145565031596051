import type { TooltipPlacement } from 'antd/lib/tooltip';
import { type ReactNode, useEffect, useState } from 'react';
import type { ButtonProps } from 'refreshed-component/atoms/Button';
import { CheckBox, type CheckBoxProps } from 'refreshed-component/atoms/CheckBox';
import { DatePicker } from 'refreshed-component/atoms/DatePicker';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { HitArea } from 'refreshed-component/atoms/HitArea';
import { Input } from 'refreshed-component/atoms/Input';
import { RadioBox, type RadioBoxProps } from 'refreshed-component/atoms/RadioBox';
import { Colors, FontSize, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import {
  Accordion,
  Button,
  ButtonSize,
  ButtonVariant,
  Divider,
  Icon,
  IconName,
  Text,
  TypographyVariant,
} from '@aircarbon/ui';

import { formatDate } from 'utils/helpers';

import { InputRange } from './Filter/components/InputRange';

export type FilterCheckBox = {
  type: 'check-box';
  label: string;
  list: CheckBoxProps[];
};

export type FilterRadioBox = {
  type: 'radio-box';
  label: string;
  list: RadioBoxProps[];
};

export type FilterRangeInput = {
  type: 'range-input';
  label: string;
  range: {
    from: {
      label: string;
      placeholder: string;
    };
    to: {
      label: string;
      placeholder: string;
    };
  };
};

export type FilterDateRangeInput = {
  type: 'date-range-input';
  label: string;
};

export type FilterType = FilterCheckBox | FilterRadioBox | FilterRangeInput | FilterDateRangeInput;

export type Filters = {
  [key: string]: FilterType;
};

export type FilterSelectionType =
  | FilterDateRangeSelection
  | RangeInputSelection
  | RadioBoxSelection
  | CheckBoxSelection;

export type FilterDateRangeSelection = {
  type: 'date-range-input';
  range: {
    startDate: Date;
    endDate: Date;
  };
};

export type RangeInputSelection = {
  type: 'range-input';
  selection: {
    from?: number;
    to?: number;
  };
};

export type RadioBoxSelection = {
  type: 'radio-box';
  selection: string | number | undefined;
};

export type CheckBoxSelection = {
  type: 'check-box';
  selection: (string | number)[] | undefined;
};

export type FilterSelections<T extends Filters> = {
  [K in keyof T]?: T[K] extends FilterCheckBox
    ? CheckBoxSelection
    : T[K] extends FilterRadioBox
      ? RadioBoxSelection
      : T[K] extends FilterRangeInput
        ? RangeInputSelection
        : T[K] extends FilterDateRangeInput
          ? FilterDateRangeSelection
          : any;
};

type Props<T extends Filters> = {
  appearance?: ButtonProps;
  label?: string;
  list: T;
  onChange?: (selections?: FilterSelections<T>) => void;
  selections?: FilterSelections<T>;
  children?: ReactNode;
  placement?: TooltipPlacement;
};

const FilterRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 400px;
  max-height: 400px;
  > .title {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 400px;
    justify-content: space-between;
    border-bottom: 1px solid var(${Colors.gray_300});
    padding-left: var(${Spacing.large});
    padding-right: var(${Spacing.large});
    padding-top: var(${Spacing.base});
    padding-bottom: var(${Spacing.base});
  }
  > .body {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    > .item {
      height: auto;
      width: 100%;
      position: relative;
      > .header {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        padding-left: var(${Spacing.large});
        padding-right: var(${Spacing.large});
        padding-top: var(${Spacing.base});
        padding-bottom: var(${Spacing.base});
      }
      > .control {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: var(${Spacing.base});
        height: 1px;
        background-color: var(${Colors.gray_200});
        margin-left: var(${Spacing.large});
        margin-right: var(${Spacing.large});
      }
      &.expand {
        > .control {
          height: auto;
          padding-left: var(${Spacing.large});
          padding-right: var(${Spacing.large});
          padding-top: var(${Spacing.base});
          padding-bottom: var(${Spacing.base});
          background-color: var(${Colors.gray_50});
          margin-left: 0px;
          margin-right: 0px;
        }
      }
    }
  }
  > .footer {
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 300px;
    justify-content: space-between;
    border-top: 1px solid var(${Colors.gray_300});
    padding-left: var(${Spacing.large});
    padding-right: var(${Spacing.large});
    padding-top: var(${Spacing.base});
    padding-bottom: var(${Spacing.base});
    > button {
      flex: 1 1 0%;
    }
  }
`;

export const FilterDropdown = <T extends Filters>({
  appearance,
  label,
  list,
  children,
  placement,
  ...props
}: Props<T>) => {
  const [state, setState] = useState<{
    [key: string]:
      | {
          isOpen: boolean;
          selected?: [];
        }
      | undefined;
  }>({});
  const [alwaysVisible, setAlwaysVisible] = useState(false);
  const [selections = {}, setSelections] = useState<FilterSelections<T> | undefined>();
  const [dirty, setDirty] = useState<boolean>(false);

  useEffect(() => {
    setSelections({ ...(props.selections || ({} as FilterSelections<T>)) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selections]);

  const contentHook: {
    close?: (() => void) | undefined;
  } = {};

  const listOfItems = Object.values(list);
  const isHaveOnlyOneItem = listOfItems.length === 1;
  const finalLabel = isHaveOnlyOneItem ? listOfItems[0].label : label;
  const [search, setSearch] = useState<{ [index: string]: string | undefined }>({});

  return (
    <Dropdown
      alwaysVisible={alwaysVisible}
      onShowChange={() => {
        if (dirty) setSelections({ ...(props.selections || ({} as FilterSelections<T>)) });
      }}
      config={{
        variant: 'button',
        color: 'outlined',
        icon: {
          right: null,
          left: <Icon size="0.75rem" name={IconName.Filter} />,
        },
        ...(appearance || ({} as ButtonProps as any)),
      }}
      content={{
        style: {
          padding: '0',
          overflow: 'hidden',
          maxHeight: '400px',
        },
        placement: placement || 'bottomRight',
        after: (
          <FilterRoot>
            <div className="title">
              <Text variant={TypographyVariant.subtitle1}>{finalLabel || 'Filters'}</Text>
              <Button
                variant={ButtonVariant.ghost}
                onPress={() => {
                  isHaveOnlyOneItem
                    ? props.onChange?.({
                        ...(props.selections || ({} as FilterSelections<T>)),
                        [Object.keys(list)[0]]: undefined,
                      })
                    : props.onChange?.({});
                }}
                size={ButtonSize.xs}
              >
                {isHaveOnlyOneItem ? 'Clear' : 'Clear all'}
              </Button>
            </div>
            <div className="body">
              {Object.entries(list).map(([key, itemProps], index) => {
                const selectionsObject = (selections as FilterSelections<T>)?.[key];
                const isOpen = isHaveOnlyOneItem ? true : state[key]?.isOpen === true;

                return (
                  <>
                    <Accordion
                      title={itemProps.label}
                      isExpanded={isOpen}
                      onToggle={() =>
                        setState({
                          ...state,
                          [key]: {
                            isOpen: !state[key]?.isOpen,
                            selected: state[key]?.selected,
                          },
                        })
                      }
                    >
                      {(itemProps.type === 'check-box' || itemProps.type === 'radio-box') &&
                        itemProps.list.length > 10 && (
                          <Input
                            className="w-full"
                            placeholder="Search"
                            config={{
                              size: 'base',
                              color: 'gray',
                              postfix: (
                                <HitArea
                                  width={20}
                                  height={20}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setSearch({
                                      ...search,
                                      [key]: undefined,
                                    });
                                  }}
                                >
                                  <Icon name={IconName.X} size="0.625rem" />
                                </HitArea>
                              ),
                              prefix: <Icon name={IconName.Search} size="0.875rem" />,
                            }}
                            value={search[key] || ''}
                            onChange={(event) => {
                              setSearch({
                                ...search,
                                [key]: event.target.value.trim() || undefined,
                              });
                            }}
                          />
                        )}
                      {itemProps.type === 'check-box' &&
                        itemProps.list.map((item) => {
                          const selectionsObject = (selections as FilterSelections<T>)?.[key];
                          const selection =
                            selectionsObject?.type === 'check-box' ? selectionsObject.selection : undefined;
                          const isSelected = selection?.includes(item.id);
                          const searchLabel = search[key]?.toLocaleLowerCase();
                          if (searchLabel && !item.label.toLocaleLowerCase().startsWith(searchLabel)) return <></>;
                          return (
                            <CheckBox
                              key={item.id.toString()}
                              onClick={() => {
                                setDirty(true);
                                setSelections({
                                  ...(selections || ({} as FilterSelections<T>)),
                                  [key]: {
                                    type: 'check-box',
                                    selection: isSelected
                                      ? (selection || []).filter((id: string | number) => id !== item.id)
                                      : [...(selection || []), item.id],
                                  },
                                });
                              }}
                              id={item.id}
                              label={item.label}
                              isSelected={isSelected}
                            />
                          );
                        })}
                      {itemProps.type === 'radio-box' &&
                        itemProps.list.map((item) => {
                          const selectionsObject = (selections as FilterSelections<T>)?.[key];
                          const selection =
                            selectionsObject?.type === 'radio-box' ? selectionsObject.selection : undefined;
                          const isSelected = selection === item.id;
                          const searchLabel = search[item.id]?.toLocaleLowerCase();
                          if (searchLabel && !item.label.toLocaleLowerCase().startsWith(searchLabel)) return <></>;
                          return (
                            <RadioBox
                              key={item.id.toString()}
                              onClick={() => {
                                setDirty(true);
                                setSelections({
                                  ...(selections || ({} as FilterSelections<T>)),
                                  [key]: {
                                    type: 'radio-box',
                                    selection: isSelected ? undefined : item.id,
                                  },
                                });
                              }}
                              id={item.id}
                              label={item.label}
                              isSelected={isSelected}
                            />
                          );
                        })}
                      {itemProps.type === 'range-input' && (
                        <InputRange
                          fromPlaceholder={itemProps.range.from.placeholder}
                          toPlaceholder={itemProps.range.to.placeholder}
                          fromLabel={itemProps.range.from.label}
                          toLabel={itemProps.range.to.label}
                          from={((selections as FilterSelections<T>)?.[key] as RangeInputSelection)?.selection?.from}
                          to={((selections as FilterSelections<T>)?.[key] as RangeInputSelection)?.selection?.to}
                          onChange={(params) => {
                            setDirty(true);
                            setSelections({
                              ...(selections || ({} as FilterSelections<T>)),
                              [key]: {
                                type: 'range-input',
                                selection: params,
                              },
                            });
                          }}
                        />
                      )}
                      {itemProps.type === 'date-range-input' && (
                        <DatePicker
                          onShowChange={(value) => setAlwaysVisible(value)}
                          selectsRange={true}
                          startDate={(selectionsObject as FilterDateRangeSelection | undefined)?.range?.startDate}
                          endDate={(selectionsObject as FilterDateRangeSelection | undefined)?.range?.endDate}
                          onChange={(value) => {
                            setDirty(false);
                            props.onChange?.({
                              ...(selections || ({} as FilterSelections<T>)),
                              [key]: {
                                type: 'date-range-input',
                                range: {
                                  startDate: value[0] || undefined,
                                  endDate: value[1] || undefined,
                                },
                              },
                            });
                          }}
                        />
                      )}
                    </Accordion>
                    {index !== Object.entries(list).length - 1 && <Divider />}
                  </>
                );
              })}
            </div>
            <div className="footer">
              <Button
                variant={ButtonVariant.secondary}
                onPress={() => {
                  setDirty(false);
                  props.onChange?.({ ...(selections || ({} as FilterSelections<T>)) });
                  contentHook.close?.();
                }}
              >
                Apply Filters
              </Button>
            </div>
          </FilterRoot>
        ),
        contentHook,
      }}
    >
      {children || label || 'Filter'}
    </Dropdown>
  );
};

const FilterSelectionsRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background: var(${Colors.gray_0}, #fff);
  padding: var(${Spacing.small});
  gap: var(${Spacing.xs});
  overflow-y: auto;
  overflow-x: hidden;
  > .body {
    display: flex;
    flex-direction: row;
    gap: var(${Spacing.xs});
    flex-wrap: wrap;
    overflow: hidden;
    > button .item {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      left: 0;
      right: 0;
      display: flex;
      font-size: var(${FontSize.small});
      cursor: pointer;
      color: var(${Colors.gray_0});
      gap: var(${Spacing.xs});
      white-space: pre;
      > .label {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > div > svg {
        cursor: pointer !important;
      }
      > div > svg:hover {
        cursor: pointer !important;
      }
    }
  }
`;

export const FilterSelections = <T extends Filters>({ appearance, label, list, ...props }: Props<T>) => {
  const [selections, setSelections] = useState<FilterSelections<T> | undefined>();

  useEffect(() => {
    setSelections({ ...(props.selections || ({} as FilterSelections<T>)) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selections]);

  const haveAnySelection = Object.entries(selections || {}).find(([key, item]: [string, FilterSelectionType]) => {
    if (item?.type === 'check-box') return item.selection?.length;
    if (item?.type === 'radio-box') return item.selection !== undefined;
    if (item?.type === 'date-range-input') return item.range !== undefined;
  });

  return haveAnySelection ? (
    <FilterSelectionsRoot>
      <Icon size="0.75rem" name={IconName.Filter} />
      {label || 'Filter'}
      {haveAnySelection && (
        <div className="body">
          {Object.entries(selections || {}).map(([key, item]: [string, FilterSelectionType]) => {
            const config = list[key];
            // eslint-disable-next-line array-callback-return
            if (!item || !config) return;
            if (item.type === 'check-box') {
              const selectionList = (config as FilterCheckBox).list.filter((item) => {
                const selectionsObject = selections?.[key];
                const selection = selectionsObject?.type === 'check-box' ? selectionsObject.selection : undefined;
                return selection?.includes(item.id);
              });
              return selectionList.length ? (
                <FilterDropdown
                  appearance={{
                    icon: {
                      left: null,
                      right: null,
                    },
                    color: 'secondary',
                    size: 'xs',
                  }}
                  list={{ [key]: config } as Filters as any}
                  selections={props.selections}
                  onChange={props.onChange}
                  placement="bottomLeft"
                >
                  <div className="item">
                    <div className="label">
                      {config.label} {selectionList.length > 1 ? `(${selectionList.length})` : ''}:{' '}
                      {selectionList
                        .map((item) => {
                          return item.label;
                        })
                        .join(', ')}
                    </div>
                    <div
                      onClick={() => {
                        props.onChange?.({
                          ...(selections as FilterSelections<T>),
                          [key]: undefined,
                        });
                      }}
                    >
                      <Icon size="0.75rem" name={IconName.X} />
                    </div>
                  </div>
                </FilterDropdown>
              ) : undefined;
            }

            if (item.type === 'radio-box') {
              const selectionList = (config as FilterRadioBox).list.filter((item) => {
                const selectionsObject = selections?.[key];
                const selection = selectionsObject?.type === 'radio-box' ? selectionsObject.selection : undefined;
                return selection === item.id;
              });
              return selectionList.length ? (
                <FilterDropdown
                  appearance={{
                    icon: {
                      left: null,
                      right: null,
                    },
                    color: 'secondary',
                    size: 'xs',
                  }}
                  list={{ [key]: config } as Filters as any}
                  selections={props.selections}
                  onChange={props.onChange}
                  placement="bottomLeft"
                >
                  <div className="item">
                    <div className="label">
                      {config.label} {selectionList.length > 1 ? `(${selectionList.length})` : ''}:{' '}
                      {selectionList
                        .map((item) => {
                          return item.label;
                        })
                        .join(', ')}
                    </div>
                    <div
                      onClick={() => {
                        props.onChange?.({
                          ...(selections as FilterSelections<T>),
                          [key]: undefined,
                        });
                      }}
                    >
                      <Icon size="0.75rem" name={IconName.X} />
                    </div>
                  </div>
                </FilterDropdown>
              ) : undefined;
            }

            if (item.type === 'date-range-input') {
              return item.range ? (
                <FilterDropdown
                  appearance={{
                    icon: {
                      left: null,
                      right: null,
                    },
                    color: 'secondary',
                    size: 'xs',
                  }}
                  list={{ [key]: config } as Filters as any}
                  selections={props.selections}
                  onChange={props.onChange}
                  placement="bottomLeft"
                >
                  <div className="item">
                    <div className="label">
                      {item?.range?.startDate &&
                        item?.range?.endDate &&
                        `Start: ${formatDate(item?.range?.startDate)} - End: ${formatDate(item?.range?.endDate)}`}
                    </div>
                    <div
                      onClick={() => {
                        props.onChange?.({
                          ...(selections as FilterSelections<T>),
                          [key]: undefined,
                        });
                      }}
                    >
                      <Icon size="0.75rem" name={IconName.X} />
                    </div>
                  </div>
                </FilterDropdown>
              ) : undefined;
            }
          })}
        </div>
      )}
    </FilterSelectionsRoot>
  ) : (
    <></>
  );
};
